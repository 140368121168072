<template>
  <div class="home">
    <Btnclick />
    <img alt="Vue logo" src="../assets/logo.png">
    
  </div>
</template>



<script>
// @ is an alias to /src
import Btnclick from '@/components/btn_click.vue'

export default {
  name: 'Btnclick',
  components: {
    Btnclick
  }
}
</script>

<template>
  <div id="main">
  <Header @set-email="setUserEmail" />
  <main id="top">
    <div class="select_btn">
      <button class="active first_btn"><figure><img src="../assets/img/calendar_icon.png" width="20" height="20"></figure><p>calendar</p></button>
      <button><router-link :to="{name: 'list'}"><figure><img src="../assets/img/list_icon.png" width="20" height="20"></figure><p>list</p></router-link></button>
      <router-link :to="{name: 'daily', params: {day_from_calendar : to_day}}"><button><span>{{ only_day }}</span><p>today</p></button></router-link>
    </div>
    <section class="monthly">
      <!--<h2><span></span>Calendar<span></span></h2>-->
      <div class="monthly_bottom">
        <button @click="monthly_bottom_btnOpen(selectedDay.selectedYear,selectedDay.selectedMonth)">
          <figure><img src="../assets/img/flag_g.png" width="16" height="16"></figure>
          <span v-if="now_goals_data.totalSales">{{ Number(now_goals_data.totalSales).toLocaleString() }}</span> 
          <span v-else>-</span>
        </button>
        <div><figure><img src="../assets/img/write.png" width="20" height="20"></figure>
        
        <span v-if="recoreds_totalSales_per_month">{{ Number(recoreds_totalSales_per_month).toLocaleString() }}</span> 
        <span v-else>-</span>
        </div>
        <button class="graf_btn" v-on:click="cheack_data_month_DataOpen(selectedDay.selectedYear,selectedDay.selectedMonth)"><figure><img src="../assets/img/graf.png" alt="グラフ" width="40px" height="40px"></figure></button>
      </div>
      <div class="monthly_calendar">
        <div class="month_tab">
          <span class="select_arrow">
            <select v-model="selectedDay.selectedYear">
              <option v-for="year in years" :key="year">{{ year }}</option>
            </select>
            </span>
          <span>/</span>
          <span class="select_arrow">
            <select v-model="selectedDay.selectedMonth">
              <option v-for="month in months" :key="month">{{ month }}</option>
            </select>
          </span>
        </div>
        <div id="calendar">
          <table>
            <tbody>
              <tr class="weeks">
                <td v-for="week in weeks" :key="week">{{ week }}</td>
              </tr>
              <tr v-for="calendar_row_data in makeCalender" :key="calendar_row_data.only_day">
                <td v-for="calendar_row in calendar_row_data" :key="calendar_row.only_day" v-bind:class="{other_month : calendar_row.other_month}">
                  <router-link :to="{ name : 'daily', params: {day_from_calendar : calendar_row.date}}">
                    <dl>
                      <dt>{{ calendar_row.only_day }}</dt>
                      <dd>
                        <p>
                          <span v-if="calendar_row.goal">{{ Number(calendar_row.goal).toLocaleString() }}</span>
                          <span v-else>-</span>
                        </p>
                        <p>
                          <span v-if="calendar_row.record">{{ Number(calendar_row.record).toLocaleString() }}</span>
                          <span v-else>-</span>
                        </p>
                      </dd>
                    </dl>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section><!-- .monthly -->
  </main>
  <Btnclick 
    ref="btn_click"
    :email="email"
    @input-new-data="new_goals_data = $event"
  />
  <Footer/>
  </div>
</template>


<script>
// @ is an alias to /src
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Btnclick from '@/components/daily_btn_click.vue'

export default {
  name: 'Home',

  components: {
    Header,
    Btnclick,
    Footer
  },
  
  data: () => ({
    /* 旧dailyタブ表示用 */
    to_day: new Date(),
    // タブ内表示
    only_day: "",

    /** セレクトボックス用 */
    years: [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
    months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10 , 11, 12],
    selectedDay: {
      selectedYear: new Date(),
      selectedMonth: new Date(),
    },
    nowMonthendDayCount:0,
    lastMonthendDayCount:0,
    nextMonthendDayCount:0,

    /* カレンダー用 */
    weeks: ['月', '火', '水', '木', '金', '土', '日'],

    /* その他 */
    // 選択年月をyyyy-mm形式で保持（子コンポーネントから返ってきた時に比較するため）
    now_year_month: "",
    
    // カレンダーの配列データに必要なデータ（選択年月とその前後1か月を取得。目標と実績両方）
    last_records_data: [],
    now_records_data: [],
    next_records_data: [],
    last_goals_data: {},
    now_goals_data: {},
    next_goals_data: {},

    // 選択年月の累計実績(now_records_dataから算出)
    recoreds_totalSales_per_month: "",

    // 子コンポーネントから目標が変更されたときに画面の数字を変更する
    new_goals_data: "",

    email: "",
  }),

  created: function() {
    this.axios.get('reGenToken', {
      headers: {
        Authorization: this.$localStorage.get('accessToken')
      }
    })
    .then((response) => {
      this.$localStorage.set('accessToken', response.data.accessToken)
    })
    .catch((e) => {
      console.log(e)
      this.$router.push({ name: 'Login' })
    })
  },

  mounted () {
    this.selectedDay.selectedYear = this.selectedDay.selectedYear.getFullYear();
    this.selectedDay.selectedMonth = this.selectedDay.selectedMonth.getMonth() + 1;

    /** to_day:yyyy_mm_dd形式で今日の日付を保持 */
    this.to_day = this.to_day.getFullYear() + "-" + ("00" + (this.to_day.getMonth()+1)).slice(-2) + "-" + ("00" + this.to_day.getDate()).slice(-2);

    let object_day = new Date();
    this.only_day = object_day.getDate();
  },
  
  watch: {
    selectedDay :{
      handler : function(){
        let self = this;
        let endDate = new Date(this.selectedDay.selectedYear, this.selectedDay.selectedMonth,  0) // 月の最後の日
        this.nowMonthendDayCount = endDate.getDate() // 月の末日
        let lastMonthEndDate = new Date(this.selectedDay.selectedYear, this.selectedDay.selectedMonth - 1, 0) // 前月の最後の日の情報
        this.lastMonthendDayCount = lastMonthEndDate.getDate() // 前月の末日
        let nextMonthEndDate = new Date(this.selectedDay.selectedYear, this.selectedDay.selectedMonth + 1,  0) // 翌月の最後の日
        this.nextMonthendDayCount = nextMonthEndDate.getDate() // 翌月の末日

        /**
         * 先月分
         */
        /** 目標取得 */
        let now_year_month_object = new Date(this.selectedDay.selectedYear,this.selectedDay.selectedMonth - 1,1);
        this.axios.get(`goals/monthly/${this.selectedDay.selectedYear}/${this.selectedDay.selectedMonth - 1}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          if (response.data !== "") {
            self.last_goals_data = response.data;
          } else {
            self.last_goals_data = {
              "totalSales": "",
              "techSales": "",
              "itemSales": "",
              "otherSales": "",
              "numberOfCustomers": "",
              "time": "",
            };
          }
        })
        .catch((e) => {
          console.log(e);
        });

        /** 実績取得 */
        this.axios.get(`records/monthly/${this.selectedDay.selectedYear}/${this.selectedDay.selectedMonth - 1}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          if (response.data !== "") {
            self.last_records_data = response.data;
          } else {
            self.last_records_data = [{
              "totalSales": "",
              "techSales": "",
              "itemSales": "",
              "otherSales": "",
              "numberOfCustomers": "",
              "time": "",
              "date": ""
            }];
          }
        })
        .catch((e) => {
          console.log(e);
        });

        /**
         * 今月分
         */
        /** 目標取得 */
        this.now_year_month = now_year_month_object.getFullYear() + "-" + ("00" + (now_year_month_object.getMonth() + 1)).slice(-2);
        this.axios.get(`goals/monthly/${this.selectedDay.selectedYear}/${this.selectedDay.selectedMonth}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          if (response.data !== "") {
            self.now_goals_data = response.data;
          } else {
            self.now_goals_data = {
              "totalSales": "",
              "techSales": "",
              "itemSales": "",
              "otherSales": "",
              "numberOfCustomers": "",
              "time": "",
            };
          }
        })
        .catch((e) => {
          console.log(e);
        });

        /** 実績取得 */
        this.axios.get(`records/monthly/${this.selectedDay.selectedYear}/${this.selectedDay.selectedMonth}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          if (response.data !== "") {
            self.now_records_data = response.data;
          } else {
            self.now_records_data = [{
              "totalSales": "",
              "techSales": "",
              "itemSales": "",
              "otherSales": "",
              "numberOfCustomers": "",
              "time": "",
              "date": ""
            }];
          }
        })
        .catch((e) => {
          console.log(e);
        });

        /**
         * 来月分
         */
        /** 目標取得 */
        this.axios.get(`goals/monthly/${this.selectedDay.selectedYear}/${parseInt(this.selectedDay.selectedMonth) + 1}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          if (response.data !== "") {
            self.next_goals_data = response.data;
          } else {
            self.next_goals_data = {
              "totalSales": "",
              "techSales": "",
              "itemSales": "",
              "otherSales": "",
              "numberOfCustomers": "",
              "time": "",
            };
          }
        })
        .catch((e) => {
          console.log(e);
        });

        /** 実績取得 */
        this.axios.get(`records/monthly/${this.selectedDay.selectedYear}/${parseInt(this.selectedDay.selectedMonth) + 1}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          if (response.data !== "") {
            self.next_records_data = response.data;
          } else {
            self.next_records_data = [{
              "totalSales": "",
              "techSales": "",
              "itemSales": "",
              "otherSales": "",
              "numberOfCustomers": "",
              "time": "",
              "date": ""
            }];
          }
        })
        .catch((e) => {
          console.log(e);
        });

      },
      deep:true
    },
    new_goals_data:{
      handler : function(){
        if (this.new_goals_data !== "") {
          if (this.new_goals_data.year === this.selectedDay.selectedYear && this.new_goals_data.month === this.selectedDay.selectedMonth) {
            this.now_goals_data = this.new_goals_data;
          } else {
            this.selectedDay.selectedYear = this.new_goals_data.year;
            this.selectedDay.selectedMonth = this.new_goals_data.month;
          }
        }
      },
      deep:true
    },
    now_records_data:{
      handler : function() {
        if (this.now_records_data.length > this.nowMonthendDayCount) {
          this.now_records_data = this.now_records_data.slice(0,this.nowMonthendDayCount);
        }
        if (this.now_records_data === "") {
          this.recoreds_totalSales_per_month = "";
        } else {
          let record_data_array = this.now_records_data.filter(function(item) {
            if (item.created_at) {
              return true;
            }
          });
          if (record_data_array.length > 0) {
            this.recoreds_totalSales_per_month = 0;
            for (let i = 0; i < this.now_records_data.length; i++) {
              this.recoreds_totalSales_per_month += Number(this.now_records_data[i].totalSales);
            }
          } else {
            this.recoreds_totalSales_per_month = "";
          }
        }
      },
      deep:true
    },
    last_records_data:{
      handler : function(){
        if (this.last_records_data.length > this.lastMonthendDayCount) {
          this.last_records_data = this.last_records_data.slice(0,this.lastMonthendDayCount);
        }
      },
      deep:true
    },
    next_records_data:{
      handler : function(){
        if (this.next_records_data.length > this.nextMonthendDayCount) {
          this.next_records_data = this.next_records_data.slice(0,this.nextMonthendDayCount);
        }
      },
      deep:true
    },
  },

  computed: {
    makeCalender : function() {
      const year = this.selectedDay.selectedYear
      const month = this.selectedDay.selectedMonth
      const startDate = new Date(year, month - 1, 1) // 月の最初の日
      const startDay = startDate.getDay() === 0 ? 6 : startDate.getDay() - 1; // 月の最初の日の曜日
      let dayCount = 1 // 日にち

      let all_calendar_data = [];
      for (let w = 0; w < 6; w++) {
        for (let d = 0; d < 7; d++) {
          if (w == 0 && d < startDay) {
            // 1行目で1日の曜日の前
            let only_day = this.lastMonthendDayCount - startDay + d + 1;
            let last_month = new Date(year , month - 2 , only_day);
            let date = last_month.getFullYear() + "-" + ("00" + (last_month.getMonth()+1)).slice(-2) + "-" + ("00" + last_month.getDate()).slice(-2);
            let record = "";
            this.last_records_data.filter(function(item) {
              if (item.date === date) {
                record = item.totalSales;
              }
            });
            let goal = this.last_goals_data.totalSales === "" ? "-" : Math.round(this.last_goals_data.totalSales / this.lastMonthendDayCount);
            let data = {
              "only_day": only_day,
              "date": date,
              "other_month":true,
              "record":record,
              "goal":goal,
            };
            all_calendar_data.push(data);
          } else if (dayCount > this.nowMonthendDayCount) {
            // 末尾の日数を超えた
            let only_day = dayCount - this.nowMonthendDayCount;
            let next_month = new Date(year , month, only_day);
            let date = next_month.getFullYear() + "" + ("00" + (next_month.getMonth()+1)).slice(-2) + "-" + ("00" + next_month.getDate()).slice(-2);
            let record = "";
            this.next_records_data.filter(function(item) {
              if (item.date === date) {
                record = item.totalSales;
              }
            });
            let goal = this.next_goals_data.totalSales === "" ? "" : Math.round(this.next_goals_data.totalSales / this.nextMonthendDayCount);
            let data = {
              "only_day": only_day,
              "date": date,
              "other_month":true,
              "record":record,
              "goal":goal,
            };
            all_calendar_data.push(data);
            dayCount++;
          } else {
            let only_day = dayCount;
            let now_month = new Date(year , month - 1, only_day);
            let date = now_month.getFullYear() + "-" + ("00" + (now_month.getMonth()+1)).slice(-2) + "-" + ("00" + now_month.getDate()).slice(-2);
            let record = "";
            this.now_records_data.filter(function(item) {
              if (item.date === date) {
                record = item.totalSales;
              }
            });
            let goal = this.now_goals_data.totalSales === "" ? "" : Math.round(this.now_goals_data.totalSales / this.nowMonthendDayCount);
            let data = {
              "only_day": only_day,
              "date": date,
              "other_month":false,
              "record":record,
              "goal":goal,
            };
            all_calendar_data.push(data);
            dayCount++
          }
        }
      }
      return [all_calendar_data.slice(0,7),all_calendar_data.slice(7,14), all_calendar_data.slice(14,21), all_calendar_data.slice(21,28),all_calendar_data.slice(28,35), all_calendar_data.slice(35,42)];
    }
  },

  methods: {
    monthly_bottom_btnOpen(year, month) {
      this.$refs.btn_click.monthly_bottom_btnOpen(year, month)
    },
    cheack_data_month_DataOpen (year,month) {
      // グラフ用
      this.$refs.btn_click.get_yearly_graph(year)
      this.$refs.btn_click.get_monthly_graph(year, month)
      
      this.$refs.btn_click.cheack_data_month_DataOpen(year, month)
    },
    setUserEmail(email) {
      this.email = email
    }
  },
}
</script>


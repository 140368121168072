<template>
  <div id="main">

  <Header @set-email="setUserEmail" />

  <main id="top">
    <div class="select_btn">
      <!-- <button class="first_btn" onclick="location.href='/'"><figure><img src="../assets/img/calendar_icon.png" width="20" height="20"></figure>calendar</button>
      <button onclick="location.href='/list'"><figure><img src="../assets/img/list_icon.png" width="20" height="20"></figure>list</button> -->
      <button class="first_btn"><router-link :to="{ path : '/'}"><figure><img src="../assets/img/calendar_icon.png" width="20" height="20"></figure><p>calendar</p></router-link></button>
      <button><router-link :to="{ name : 'list'}"><figure><img src="../assets/img/list_icon.png" width="20" height="20"></figure><p>list</p></router-link></button>
      <button class="active"><p class="select" v-show="today != day">select</p><span>{{only_day}}</span><p v-show="today === day">today</p></button>
    </div>

    <section class="daily">
      <div class="daily_header">
        <div class="day_select"><input type="date" v-model="day" ></div>
        <!--<button @click="monthly_bottom_btnOpen(year,month)">
          <figure><img src="../assets/img/flag_g.png" width="16" height="16"></figure>
          {{ goals_totalSales_per_month }}
        </button>-->
        <button class="graf_btn" @click="cheack_data_month_DataOpen(year,month)"><figure><img src="../assets/img/graf.png" alt="グラフ" width="40px" height="40px"></figure></button>
      </div>

      <div class="daily_days">
        <div class="daily_days-cell">
          <div class="cell_inner">
            <div>
              総売上
              <!--
              <span>{{only_day}}</span>
              <span>{{day_of_week}}曜日</span>
              -->
            </div>
            <div>
              <p v-if="total_goals_per_records">{{ total_goals_per_records }}%</p>
              <p v-else>-%</p>
            </div>
            <div>
              <figure><img src="../assets/img/flag_g.png" width="16" height="16"></figure>
              <p>
                <span v-if="goals_totalSales_per_day">{{ goals_totalSales_per_day.toLocaleString() }}</span>
                <span v-else>-</span>
              </p>
            </div>
          </div>
          <button @click="monthly_bottom_btnOpen_do(day)">
            <figure><img src="../assets/img/write.png" width="22" height="22"></figure>
            <p>
              <span v-if="records_totalSales">{{ records_totalSales.toLocaleString() }}</span>
              <span v-else>-</span>
            </p>
          </button>
        </div>

        <div class="daily_days-cell">
          <div class="cell_inner">
            <div>
              <p>技術売上</p>
            </div>
            <div>
              <p v-if="tech_goals_per_records">{{ tech_goals_per_records }}%</p>
              <p v-else>-%</p>
            </div>
            <div class="">
              <figure><img src="../assets/img/flag_g.png" width="16" height="16"></figure>
              <p>
                <span v-if="goals_techSales">{{ goals_techSales.toLocaleString() }}</span>
                <span v-else>-</span>
              </p>
            </div>
          </div>
          <div class="pink">
            <figure><img src="../assets/img/write.png" width="18" height="18"></figure>
            <p>
              <span v-if="records_techSales">{{ records_techSales.toLocaleString() }}</span>
              <span v-else>-</span>
            </p>
          </div>
        </div>

        <div class="daily_days-cell">
          <div class="cell_inner">
            <div>
              <p>商品売上</p>
            </div>
            <div>
              <p v-if="item_goals_per_records">{{ item_goals_per_records }}%</p>
              <p v-else>-%</p>
            </div>
            <div class="">
              <figure><img src="../assets/img/flag_g.png" width="16" height="16"></figure>
              <p>
                <span v-if="goals_itemSales">{{ goals_itemSales.toLocaleString() }}</span>
                <span v-else>-</span>
              </p>
            </div>
          </div>
          <div class="pink">
            <figure><img src="../assets/img/write.png" width="18" height="18"></figure>
            <p>
              <span v-if="records_itemSales">{{ records_itemSales.toLocaleString() }}</span>
              <span v-else>-</span>
            </p>
          </div>
        </div>

        <div class="daily_days-cell">
          <div class="cell_inner">
            <div>
              <p>他売上</p>
            </div>
            <div>
              <p v-if="other_goals_per_records">{{ other_goals_per_records }}%</p>
              <p v-else>-%</p>
            </div>
            <div class="">
              <figure><img src="../assets/img/flag_g.png" width="16" height="16"></figure>
              <p>
                <span v-if="goals_otherSales">{{ goals_otherSales.toLocaleString() }}</span>
                <span v-else>-</span>
              </p>
            </div>
          </div>
          <div class="pink">
            <figure><img src="../assets/img/write.png" width="18" height="18"></figure>
            <p>
              <span v-if="records_otherSales">{{ records_otherSales.toLocaleString() }}</span>
              <span v-else>-</span>
            </p>
          </div>
        </div>

        <div class="daily_days-cell">
          <div class="cell_inner">
            <div>
              <p>客数</p>
            </div>
            <div>
              <p v-if="customer_goals_per_records">{{ customer_goals_per_records }}%</p>
              <p v-else>-%</p>
            </div>
            <div class="">
              <figure><img src="../assets/img/flag_g.png" width="16" height="16"></figure>
              <p>
                <span v-if="goals_numberOfCustomers">{{ goals_numberOfCustomers.toLocaleString() }}</span>
                <span v-else>-</span>
              </p>
            </div>
          </div>
          <div class="pink">
            <figure><img src="../assets/img/guest.png" width="18" height="18"></figure>
            <p>
              <span v-if="records_numberOfCustomers">{{ records_numberOfCustomers.toLocaleString() }}</span>
              <span v-else>-</span>
            </p>
          </div>
        </div>
        <div class="daily_days-cell">
          <div class="cell_inner">
            <div>
              <p>仕事時間</p>
            </div>
            <div class="">

              <figure><img src="../assets/img/time_g.png" width="18" height="18"></figure>
              
                
                <p v-if="goals_time"><span>{{ goals_time }}</span> h</p>
                <p v-else><span>-</span> h</p>
            </div>
          </div>
          <div class="pink">
            <figure><img src="../assets/img/time.png" width="18" height="18"></figure>
            {{ records_time }}<span v-if="records_time === ''">-</span>h

          </div>
        </div>

        <dl class="daily_days-analysis">
          <dt class="headline">分析</dt>
      
          <dd class="daily_days-cell">
            <dl>
              <dt>総客単価</dt>
              <dd><figure><img src="../assets/img/guest.png" width="18" height="18"></figure>
                {{ total_per_customer.toLocaleString() }}
                <span v-if="total_per_customer.toLocaleString() === ''">-</span>
              </dd>
            </dl>
            <dl>
              <dt>技術客単価</dt>
              <dd><figure><img src="../assets/img/guest.png" width="18" height="18"></figure>
                {{ tech_per_customer.toLocaleString() }}
                <span v-if="tech_per_customer.toLocaleString() === ''">-</span>
              </dd>
            </dl>
          </dd>
          <dd class="daily_days-cell">
            <dl>
              <dt>商品客単価</dt>
              <dd><figure><img src="../assets/img/guest.png" width="18" height="18"></figure>
                {{ item_per_customer.toLocaleString() }}
                <span v-if="item_per_customer.toLocaleString() === ''">-</span>
              </dd>
            </dl>
            <dl>
              <dt>他客単価</dt>
              <dd><figure><img src="../assets/img/guest.png" width="18" height="18"></figure>
                {{ other_per_customer.toLocaleString() }}
                <span v-if="other_per_customer.toLocaleString() === ''">-</span>
              </dd>
            </dl>
          </dd>
          <dd class="daily_days-cell">
            <dl>
              <dt>１時間売上</dt>
              <dd><figure><img src="../assets/img/guest.png" width="18" height="18"></figure>
                {{ total_per_time.toLocaleString() }}
                <span v-if="total_per_time.toLocaleString() === ''">-</span>
              </dd>
            </dl>
          </dd>
        </dl>
      </div>
    </section><!-- .daily -->


  </main>

  <Btnclick ref="btn_click" 
    :email="email"
    v-on:do_new_data="new_records_data = $event" 
    v-on:input_new_data="new_goals_data = $event"
  />
  <Footer/>
  </div>
</template>


<script>
// @ is an alias to /src
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Btnclick from '@/components/daily_btn_click.vue'


export default {
  name: 'Home',
  components: {
    Header,
    Btnclick,
    Footer
  },
  data: () => ({
    /** 日付系 */
    day:"",                                //選択された日付
    month:"",                              //選択された月
    year:"",                               //選択された年
    only_day:"",                           //日にちのみ表示用
    today:"",                              //今日を取得
    // day_of_week:"",                        //曜日表示用
    days_of_month:"",                      //日数の保持
    /** 目標系 */
    goals_totalSales_per_month:"",         //月総目標
    goals_totalSales_per_day:"",           //日総目標
    goals_techSales:"",                    //日技術目標
    goals_itemSales:"",                    //日商品目標
    goals_otherSales:"",                   //日他目標
    goals_numberOfCustomers:"",            //日客目標
    goals_time:"",            //日客目標
    /** 実績系 */
    records_totalSales:"",                 //総実績
    records_techSales:"",                  //技術実績
    records_itemSales:"",                  //商品実績
    records_otherSales:"",                 //他取得
    records_numberOfCustomers:"",          //客実績
    records_time:"",                 //営業時間実績

    /** 子コンポーネントから */
    new_records_data: "",
    new_goals_data: "",
    email: "",
  }),
  mounted () {
    // もし他画面からdayを持ってこないのなら今日の日付を入れる
    this.day = this.$route.params.day_from_calendar;
    if (this.day === "") {
      let today = new Date();
      this.day = today.getFullYear() + "-" + ("00" + (today.getMonth()+1)).slice(-2) + "-" + ("00" + today.getDate()).slice(-2);
    }
  },
  watch :{
    day:{
      handler : function(){
        let self = this;
        //url変更
        if (this.$route.params.day_from_calendar !== this.day) {
          this.$route.params.day_from_calendar = this.day;
          this.$router.push({name:"daily",path:self.day});
        }
        /** 日付系の処理 */
        //日付をdateオブジェクト化:object_day
        let array_day = this.day.split("-");
        let object_day = new Date(array_day[0],array_day[1] - 1,array_day[2]);
        this.only_day = object_day.getDate()
        //選択された月:this.month
        this.month = object_day.getMonth() + 1;
        //選択された月:this.month
        this.year = object_day.getFullYear();
        //日にちのみ表示用:this.only_day
        let today_object = new Date();
        this.today = today_object.getFullYear() + "-" + ("00" + (today_object.getMonth()+1)).slice(-2) + "-" + ("00" + today_object.getDate()).slice(-2);
        //日数の取得:days_of_month
        let next_month = new Date(array_day[0], array_day[1] , 0);
        this.days_of_month = next_month.getDate();

        /** 目標系 */
        let year_month = this.day.split("-");
        let get_only_year = String(year_month[0]);
        let get_only_month = String(year_month[1]).length === 1 ? "0" + String(year_month[1]) : String(year_month[1]);
        let get_only_day = String(year_month[2]).length === 1 ? "0" + String(year_month[2]) : String(year_month[2]);
        this.axios.get(`goals/monthly/${get_only_year}/${get_only_month}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          if (response.data !== "") {
            self.goals_totalSales_per_month = response.data.totalSales;
            self.goals_totalSales_per_day = Math.round(response.data.totalSales/this.days_of_month);
            self.goals_techSales = Math.round(response.data.techSales/this.days_of_month);
            self.goals_itemSales = Math.round(response.data.itemSales/this.days_of_month);
            self.goals_otherSales = Math.round(response.data.otherSales/this.days_of_month);
            self.goals_numberOfCustomers = Math.round(response.data.numberOfCustomers/this.days_of_month);
            self.goals_time = Math.round(response.data.time/this.days_of_month);
          } else {
            self.goals_totalSales_per_month = "";
            self.goals_totalSales_per_day = "";
            self.goals_techSales = "";
            self.goals_itemSales = "";
            self.goals_otherSales = "";
            self.goals_numberOfCustomers = "";
            self.goals_time = "";
          }
        })
        .catch((e) => {
          console.log(e);
        });

        // 実績取得
        this.axios.get(`records/daily/${get_only_year}/${get_only_month}/${get_only_day}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          if (response.data !== "") {
            self.records_totalSales = response.data.totalSales;
            self.records_techSales = response.data.techSales;
            self.records_itemSales = response.data.itemSales;
            self.records_otherSales = response.data.otherSales;
            self.records_numberOfCustomers = response.data.numberOfCustomers;
            self.records_time = response.data.time;
          } else {
            self.records_totalSales = "";
            self.records_techSales = "";
            self.records_itemSales = "";
            self.records_otherSales = "";
            self.records_numberOfCustomers = "";
            self.records_time = "";
          }
        })
        .catch((e) => {
          console.log(e);
        });
      },
      deep:true
    },
    new_records_data:{
      handler : function(){
        if (this.new_records_data !== "") {
          this.day = this.new_records_data.date;
          this.records_totalSales = this.new_records_data.totalSales;
          this.records_techSales = this.new_records_data.techSales;
          this.records_itemSales = this.new_records_data.itemSales;
          this.records_otherSales = this.new_records_data.otherSales;
          this.records_numberOfCustomers = this.new_records_data.numberOfCustomers;
          this.records_time = this.new_records_data.time;
        }
      },
      deep:true
    },
    new_goals_data:{
      handler : function(){
        if (this.new_goals_data !== "") {
          this.goals_totalSales_per_month = this.new_goals_data.totalSales;
          this.goals_totalSales_per_day = Math.round(this.new_goals_data.totalSales/this.days_of_month);
          this.goals_techSales = Math.round(this.new_goals_data.techSales/this.days_of_month);
          this.goals_itemSales = Math.round(this.new_goals_data.itemSales/this.days_of_month);
          this.goals_otherSales = Math.round(this.new_goals_data.otherSales/this.days_of_month);
          this.goals_numberOfCustomers = Math.round(this.new_goals_data.numberOfCustomers/this.days_of_month);
          this.goals_time = Math.round(this.new_goals_data.time/this.days_of_month);
        }
      },
      deep:true
    },
  },
  computed : {
    //総売上達成率
    total_goals_per_records : function () {
      return this.rate_round_method(this.records_totalSales, this.goals_totalSales_per_day);
    },
    //技術売上達成率
    tech_goals_per_records : function () {
      return this.rate_round_method(this.records_techSales, this.goals_techSales);
    },
    //商品売上達成率
    item_goals_per_records : function () {
      return this.rate_round_method(this.records_itemSales, this.goals_itemSales);
    },
    //他売上達成率
    other_goals_per_records : function () {
      return this.rate_round_method(this.records_otherSales, this.goals_otherSales);
    },
    //客数達成率
    customer_goals_per_records : function () {
      return this.rate_round_method(this.records_numberOfCustomers, this.goals_numberOfCustomers);
    },
    //総客単価
    total_per_customer : function () {
      return this.unit_round_method(this.records_totalSales, this.records_numberOfCustomers);
    },
    //技術客単価
    tech_per_customer : function () {
      return this.unit_round_method(this.records_techSales, this.records_numberOfCustomers);
    },
    //商品客単価
    item_per_customer : function () {
      return this.unit_round_method(this.records_itemSales, this.records_numberOfCustomers);
    },
    //他客単価
    other_per_customer : function () {
      return this.unit_round_method(this.records_otherSales, this.records_numberOfCustomers);
    },
    //１時間売上
    total_per_time : function () {
      return this.unit_round_method(this.records_totalSales, this.records_time);
    },
  },
  beforeRouteUpdate (to, from, next) {
    next();
  },
  methods: {
    monthly_bottom_btnOpen (year,month) {
      this.$refs.btn_click.monthly_bottom_btnOpen(year,month);
    },
    cheack_data_month_DataOpen (year,month) {
      this.$refs.btn_click.cheack_data_month_DataOpen(year,month)
    },
    monthly_bottom_btnOpen_do (day) {
      this.$refs.btn_click.monthly_bottom_btnOpen_do(day);
    },
    unit_round_method:function(a, b) {
      return a && b && b != 0 && isNaN(a) === false && isNaN(b) === false ? Math.round( a / b * 10) /10 : "";
    },
    rate_round_method:function(a, b) {
      return a && b && b !== 0 && isNaN(a) === false && isNaN(b) === false ? Math.round( a / b * 100) : "";
    },
    setUserEmail(email) {
      this.email = email
    }
  }
}
</script>

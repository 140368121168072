<template>

<div id="btn_click">

        <transition name="fade">
        <section id="entry"  v-show="monthly_bottom_btn">
            <transition name="fade">
                <div class=entry_position v-show="monthly_bottom_btn_input">
                    <div class="select_tab">
                        <button @click="monthly_bottom_btnOpen_easy">EASY</button>
                        <button class="active">FULL</button>
                    </div>

                    <div class="entry_inner">
                            <h2><span></span>Input<span></span></h2>
                            <button class="btn_close" type="button" @click="monthly_bottom_btnClose">×</button>

                            <!--<div class="type_select">
                                <button class="active">PLAN</button>
                                <button @click="monthly_bottom_btnOpen_do">DO</button>
                            </div>-->

                            <div class="data_select">
                                <select name="year">
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                </select>
                                <span>/</span>
                                <select name="month">
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>

                            <div class="input_head">
                                <h3>総売上</h3>
                                <button  @click="i_btnOpen">i</button>
                            </div>
                            <div class="input">
                                <div class="w80">
                                    <input type="text" placeholder="総売上">
                                </div>

                                <div class="input_scroll">
                                    <dl>
                                        <dt>技術売上</dt>
                                        <dd><input type="text" placeholder="技術売上"></dd>
                                    </dl>
                                    <dl>
                                        <dt>商品売上</dt>
                                        <dd><input type="text" placeholder="商品売上"></dd>
                                    </dl>
                                    <dl>
                                        <dt>その他売上</dt>
                                        <dd><input type="text" placeholder="その他売上"></dd>
                                    </dl>
                                    <dl>
                                        <dt>総客数</dt>
                                        <dd><input type="text" placeholder="総客数"></dd>
                                    </dl>
                                    <dl>
                                        <dt>営業時間</dt>
                                        <dd><input type="text" placeholder="営業時間"></dd>
                                    </dl>
                                </div>
                            </div>
                            <div class="input_end_btn">
                                <button>決定</button>
                            </div>
                    </div>
                </div>
            </transition>

            <transition name="fade">
                <div class=entry_position v-show="monthly_bottom_btn_input_easy">
                    <div class="select_tab">
                        <button class="active">EASY</button>
                        <button @click="monthly_bottom_btnOpen">FULL</button>
                    </div>

                    <div class="entry_inner">
                            <h2><span></span>Input<span></span></h2>
                            <button class="btn_close" type="button" @click="monthly_bottom_btnClose">×</button>

                            <!--<div class="type_select">
                                <button class="active">PLAN</button>
                                <button @click="monthly_bottom_btnOpen_do_easy">DO</button>
                            </div>-->

                            <div class="data_select">
                                <select name="year">
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                </select>
                                <span>/</span>
                                <select name="month">
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>

                            <div class="input_head">
                                <h3>総売上</h3>
                                <button  @click="i_btnOpen">i</button>
                            </div>
                            <div class="input">
                                <div class="w80">
                                    <input type="text" placeholder="総売上">
                                </div>
                            </div>
                            <div class="input_end_btn">
                                <button>決定</button>
                            </div>
                    </div>
                </div>
            </transition>

            <transition name="fade">
                <div class=entry_position v-show="monthly_bottom_btn_do">
                    <div class="select_tab">
                        <button @click="monthly_bottom_btnOpen_do_easy">EASY</button>
                        <button class="active">FULL</button>
                    </div>
            
                    <div class="entry_inner">
                            <h2><span></span>DO<span></span></h2>
                            <button class="btn_close" type="button"  @click="monthly_bottom_btnClose">×</button>
            
                            <!--<div class="type_select">
                                <button @click="monthly_bottom_btnOpen">PLAN</button>
                                <button class="active">DO</button>
                            </div>-->
            
                            <div class="data_select">
                                <select name="year">
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                </select>
                                <span>/</span>
                                <select name="month">
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                                <span>/</span>
                                <select name="month">
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>
            
                            <div class="input_head">
                                <h3>総売上</h3>
                                <button @click="i_btnOpen">i</button>
                            </div>
                            <div class="input">
                                <div class="w80">
                                    <input type="text" placeholder="総売上">
                                </div>
            
                                <div class="input_scroll">
                                    <dl>
                                        <dt>技術売上</dt>
                                        <dd><input type="text" placeholder="技術売上"></dd>
                                    </dl>
                                    <dl>
                                        <dt>商品売上</dt>
                                        <dd><input type="text" placeholder="商品売上"></dd>
                                    </dl>
                                    <dl>
                                        <dt>その他売上</dt>
                                        <dd><input type="text" placeholder="その他売上"></dd>
                                    </dl>
                                    <dl>
                                        <dt>総客数</dt>
                                        <dd><input type="text" placeholder="総客数"></dd>
                                    </dl>
                                    <dl>
                                        <dt>営業時間</dt>
                                        <dd><input type="text" placeholder="営業時間"></dd>
                                    </dl>
                                </div>
                            </div>
                            <div class="input_end_btn">
                                <button>決定</button>
                            </div>
                    </div>
                </div>
            </transition>

            <transition name="fade">
                <div class=entry_position v-show="monthly_bottom_btn_do_easy">
                    <div class="select_tab">
                        <button class="active">EASY</button>
                        <button @click="monthly_bottom_btnOpen_do">FULL</button>
                    </div>
                    <div class="entry_inner">
                            <h2><span></span>DO<span></span></h2>
                            <button class="btn_close" type="button"  @click="monthly_bottom_btnClose">×</button>
            
                            <!--<div class="type_select">
                                <button @click="monthly_bottom_btnOpen_easy">PLAN</button>
                                <button class="active">DO</button>
                            </div> -->
            
                            <div class="data_select">
                                <select name="year">
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                </select>
                                <span>/</span>
                                <select name="month">
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                                <span>/</span>
                                <select name="month">
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>
            
                            <div class="input_head">
                                <h3>総売上</h3>
                                <button @click="i_btnOpen">i</button>
                            </div>
                            <div class="input">
                                <div class="w80">
                                    <input type="text" placeholder="総売上">
                                </div>
                            </div>
                            <div class="input_end_btn">
                                <button>決定</button>
                            </div>
                    </div>
                </div>
            </transition>
        </section>
        </transition>

        <transition name="fade">
        <div id="entry_outer" v-show="p_bg" @click="monthly_bottom_btnClose" :class="{'p_bg_active': p_bg_active,'i_bg': i_bg}">
        </div>
        </transition>


        <transition name="fade">
        <div id="cheack_data_outer" v-show="cheack_data">
            <transition name="fade">
            <section id="cheack_data" v-show="month_Rank">
                <h2><span></span>Input<span></span></h2>
                <button class="btn_close" @click="monthly_bottom_btnClose">×</button>
                <div class="type_select">
                    <button @click="cheack_data_yearly_RankOpen">Yearly</button>
                    <button class="active">Monthly</button>
                </div>
                <div class="view_select">
                    <div>
                        <select name="year">
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                        </select>
                        <span>/</span>
                        <select name="month">
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                    </div>
                    <button @click="graf_i_btnOpen">i</button>
                </div>
                <figure>グラフ表示部分</figure>
                <table class="tab">
                    <tr>
                        <th></th>
                        <th>累計</th>
                        <th>日平均</th>
                        <th>達成率</th>
                    </tr>
                    <tr>
                        <td>
                            <button @click="glaf_tabOpen" :class="{'is-glaf_tab_active': glaf_tab_active}">総売上</button>
                        </td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <!---->
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>技術売上</td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>商品売上</td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>その他売上</td>
                        <td>0</td>
                        <td>0</td>
                        <td>-</td>
                    </tr>
                    <!---->

                    <tr>
                        <td>
                            <button @click="glaf_tabOpen" :class="{'is-glaf_tab_active': glaf_tab_active}">客数</button>
                        </td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <!---->
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>総客単価</td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>技術客単価</td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>商品客単価</td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <!---->


                    <tr>
                        <td>
                            <button @click="glaf_tabOpen" :class="{'is-glaf_tab_active': glaf_tab_active}">営業時間</button>
                        </td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <!---->
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>営業日数</td>
                        <td>20</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>技術客単価</td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>商品客単価</td>
                        <td>0</td>
                        <td>0</td>
                        <td>-</td>
                    </tr>
                    <!---->

                </table>
                <div class="select_tab">
                    <button @click="cheack_data_month_DataOpen">DATA</button>
                    <button class="active">RANK</button>
                </div>
            </section>
            </transition>

            <transition name="fade">
            <section id="cheack_data" v-show="month_Data">
                <h2><span></span>Input<span></span></h2>
                <button class="btn_close" @click="monthly_bottom_btnClose">×</button>
                <div class="type_select">
                    <button @click="cheack_data_yearly_DataOpen">Yearly</button>
                    <button class="active">Monthly</button>
                </div>
                <div class="view_select">
                    <div>
                        <select name="year">
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                        </select>
                        <span>/</span>
                        <select name="month">
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                    </div>
                    <button @click="graf_i_btnOpen">i</button>
                </div>
                <figure>グラフ表示部分</figure>
                <table>
                    <tr>
                        <th></th>
                        <th>累計</th>
                        <th>日平均</th>
                        <th>達成率</th>
                    </tr>
                    <tr>
                        <td>
                            <button @click="glaf_tabOpen" :class="{'is-glaf_tab_active': glaf_tab_active}">総売上</button>
                        </td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <!---->
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>技術売上</td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>商品売上</td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>その他売上</td>
                        <td>0</td>
                        <td>0</td>
                        <td>-</td>
                    </tr>
                    <!---->

                    <tr>
                        <td>
                            <button @click="glaf_tabOpen" :class="{'is-glaf_tab_active': glaf_tab_active}">客数</button>
                        </td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <!---->
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>総客単価</td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>技術客単価</td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>商品客単価</td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <!---->


                    <tr>
                        <td>
                            <button @click="glaf_tabOpen" :class="{'is-glaf_tab_active': glaf_tab_active}">営業時間</button>
                        </td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <!---->
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>営業日数</td>
                        <td>20</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>技術客単価</td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>商品客単価</td>
                        <td>0</td>
                        <td>0</td>
                        <td>-</td>
                    </tr>
                    <!---->

                </table>
                <div class="select_tab">
                    <button class="active">DATA</button>
                    <button @click="cheack_data_month_RankOpen">RANK</button>
                </div>
            </section>
            </transition>

            <transition name="fade">
            <section id="cheack_data" v-show="yearly_Data"> <!-- y data -->
                <h2><span></span>Input<span></span></h2>
                <button class="btn_close" @click="monthly_bottom_btnClose">×</button>
                <div class="type_select">
                    <button class="active">Yearly</button>
                    <button @click="cheack_data_month_DataOpen">Monthly</button>
                </div>
                <div class="view_select">
                    <div>
                        <select name="year">
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                        </select>
                        
                    </div>
                    <button @click="graf_i_btnOpen">i</button>
                </div>
                <figure>グラフ表示部分</figure>
                <table>
                    <tr>
                        <th></th>
                        <th>累計</th>
                        <th>日平均</th>
                        <th>達成率</th>
                    </tr>
                    <tr>
                        <td>
                            <button @click="glaf_tabOpen" :class="{'is-glaf_tab_active': glaf_tab_active}">総売上</button>
                        </td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <!---->
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>技術売上</td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>商品売上</td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>その他売上</td>
                        <td>0</td>
                        <td>0</td>
                        <td>-</td>
                    </tr>
                    <!---->

                    <tr>
                        <td>
                            <button @click="glaf_tabOpen" :class="{'is-glaf_tab_active': glaf_tab_active}">客数</button>
                        </td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <!---->
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>総客単価</td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>技術客単価</td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>商品客単価</td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <!---->


                    <tr>
                        <td>
                            <button @click="glaf_tabOpen" :class="{'is-glaf_tab_active': glaf_tab_active}">営業時間</button>
                        </td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <!---->
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>営業日数</td>
                        <td>20</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>技術客単価</td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>商品客単価</td>
                        <td>0</td>
                        <td>0</td>
                        <td>-</td>
                    </tr>
                    <!---->

                </table>
                <div class="select_tab">
                    <button class="active">DATA</button>
                    <button @click="cheack_data_yearly_RankOpen">RANK</button>
                </div>
            </section>
            </transition>

            <transition name="fade">
            <section id="cheack_data" v-show="yearly_Rank"> <!-- y rank -->
                <h2><span></span>Input<span></span></h2>
                <button class="btn_close" @click="monthly_bottom_btnClose">×</button>
                <div class="type_select">
                    <button class="active">Yearly</button>
                    <button @click="cheack_data_month_RankOpen">Monthly</button>
                </div>
                <div class="view_select">
                    <div>
                        <select name="year">
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                        </select>
                    </div>
                    <button @click="graf_i_btnOpen">i</button>
                </div>
                <figure>グラフ表示部分</figure>
                <table>
                    <tr>
                        <th></th>
                        <th>累計</th>
                        <th>日平均</th>
                        <th>達成率</th>
                    </tr>
                    <tr>
                        <td>
                            <button @click="glaf_tabOpen" :class="{'is-glaf_tab_active': glaf_tab_active}">総売上</button>
                        </td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <!---->
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>技術売上</td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>商品売上</td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>その他売上</td>
                        <td>0</td>
                        <td>0</td>
                        <td>-</td>
                    </tr>
                    <!---->

                    <tr>
                        <td>
                            <button @click="glaf_tabOpen" :class="{'is-glaf_tab_active': glaf_tab_active}">客数</button>
                        </td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <!---->
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>総客単価</td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>技術客単価</td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>商品客単価</td>
                        <td>9,999,999</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <!---->


                    <tr>
                        <td>
                            <button @click="glaf_tabOpen" :class="{'is-glaf_tab_active': glaf_tab_active}">営業時間</button>
                        </td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <!---->
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>営業日数</td>
                        <td>20</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>技術客単価</td>
                        <td>9,999,999</td>
                        <td>9,999,999</td>
                        <td>100％</td>
                    </tr>
                    <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                        <td>商品客単価</td>
                        <td>0</td>
                        <td>0</td>
                        <td>-</td>
                    </tr>
                    <!---->

                </table>
                <div class="select_tab">
                    <button @click="cheack_data_yearly_DataOpen">DATA</button>
                    <button class="active">RANK</button>
                </div>
            </section>
            </transition>
        </div>
        </transition>

        <transition name="fade">
        <section id="historical_data" v-show="graf_i_btn">
            <h2>
                2020
                <span>-HIstorical Data-</span>
            </h2>
            <select class="type_select">
                <option value="">累計</option>
                <option value="">全国平均</option>
            </select>
            <button class="btn_close" @click="graf_i_btnClose">×</button>

            <div class="scroll_table">
            <table>
                <tbody>
                    <tr class="day">
                        <th class="month_print">4/</th>
                        <td>1日</td>
                        <td>2日</td>
                        <td>3日</td>
                        <td>4日</td>
                    </tr>
                    <tr>
                        <th class="t_l">総売上</th>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                    </tr>
                    <tr>
                        <th>技術売上</th>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                    </tr>
                    <tr>
                        <th>商品売上</th>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                    </tr>
                    <tr>
                        <th>その他売上</th>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                    </tr>

                    <tr>
                        <th class="t_l">総客数</th>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                    </tr>
                    <tr>
                        <th>総客単価</th>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                    </tr>
                    <tr>
                        <th>技術客単価</th>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                    </tr>
                    <tr>
                        <th>商品客単価</th>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                    </tr>

                    <tr>
                        <th class="t_l">営業時間</th>
                        <td>1</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                    </tr>
                    <tr>
                        <th>営業日数</th>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                    </tr>
                    <tr>
                        <th>１時間売上</th>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                    </tr>
                    <tr>
                        <th>１日売上</th>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                        <td>1,000,000</td>
                    </tr>
                </tbody>
            </table>
            
            </div>
            <div class="btn_outer">
                <button>Send Mail</button>
            </div>

        </section>
        </transition>



        <transition name="fade">
        <section id="information" v-show="i_btn">
            <h2>-information-</h2>
            <button class="btn_close" type="button"  @click="i_btnOpen">×</button>

            <div class="scroll_table">
                <dl>
                    <dt>2020</dt>
                    <dd>
                        <table>
                            <tr>
                                <th></th>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td class="current_month">4</td>
                                <td>5</td>
                            </tr>
    
                            <tr>
                                <th>総売上</th>
                                <td>9,999,999</td>
                                <td>9,999,999</td>
                                <td>9,999,999</td>
                                <td class="current_month">9,999,999</td>
                                <td>9,999,999</td>
                            </tr>
                            <tr>
                                <th>技術売上</th>
                                <td>9,999,999</td>
                                <td>9,999,999</td>
                                <td>9,999,999</td>
                                <td class="current_month">9,999,999</td>
                                <td>9,999,999</td>
                            </tr>
                            <tr>
                                <th>商品売上</th>
                                <td>9,999,999</td>
                                <td>9,999,999</td>
                                <td>9,999,999</td>
                                <td class="current_month">9,999,999</td>
                                <td>9,999,999</td>
                            </tr>
                            <tr>
                                <th>その他売上</th>
                                <td>9,999,999</td>
                                <td>9,999,999</td>
                                <td>9,999,999</td>
                                <td class="current_month">9,999,999</td>
                                <td>9,999,999</td>
                            </tr>
                            <tr>
                                <th>総客数</th>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td class="current_month">4</td>
                                <td>5</td>
                            </tr>
                    </table>
    
                    </dd>
                </dl>
            </div>

        </section>
        </transition>
        
</div>
</template>

<style>
    .fade-enter-active, .fade-leave-active
     {
        transition: opacity .3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>

<script>
   export default {
    data: function() {
     return {
      monthly_bottom_btn: false,
      
      monthly_bottom_btn_input: false,
      monthly_bottom_btn_input_easy: false,

      monthly_bottom_btn_do: false,
      monthly_bottom_btn_do_easy: false,
      p_bg: false,
      p_bg_active: false,

      month_Rank: false,
      month_Data: false,
      yearly_Data: false,
      yearly_Rank: false,
      cheack_data: false,

      i_btn: false,
      i_bg: false,
      graf_i_btn: false,

      glaf_tab_active: false,
    }
    },
    methods: {
      monthly_bottom_btnOpen: function() {
        this.monthly_bottom_btn = true;
        this.monthly_bottom_btn_input = true;
        this.monthly_bottom_btn_input_easy = false;
        this.monthly_bottom_btn_do_easy = false;
        this.p_bg = true;
        this.p_bg_active = false;
        this.monthly_bottom_btn_do = false;
      },
      monthly_bottom_btnOpen_easy: function() {
        this.monthly_bottom_btn = true;
        this.monthly_bottom_btn_input = false;
        this.monthly_bottom_btn_input_easy = true;
        this.monthly_bottom_btn_do = false;
        this.monthly_bottom_btn_do_easy = false;
        this.p_bg = true;
        this.p_bg_active = false;
        this.monthly_bottom_btn_do = false;
      },
      monthly_bottom_btnOpen_do: function() {
        this.monthly_bottom_btn = true;
        this.monthly_bottom_btn_input = false;
        this.monthly_bottom_btn_input_easy = false;
        this.monthly_bottom_btn_do = true;
        this.monthly_bottom_btn_do_easy = false;
        this.p_bg = true;
        this.p_bg_active = true;
      },
      monthly_bottom_btnOpen_do_easy: function() {
        this.monthly_bottom_btn = true;
        this.monthly_bottom_btn_input = false;
        this.monthly_bottom_btn_input_easy = false;
        this.monthly_bottom_btn_do = false;
        this.monthly_bottom_btn_do_easy = true;
        this.p_bg = true;
        this.p_bg_active = true;
      },
      monthly_bottom_btnClose: function() {
        this.monthly_bottom_btn = false;
        this.monthly_bottom_btn_input = false;
        this.monthly_bottom_btn_input_easy = false;
        this.monthly_bottom_btn_do = false;
        this.monthly_bottom_btn_do_easy = true;
        this.p_bg = false;
        this.p_bg_active = false;
        this.i_bg = false;
        this.i_btn = false;
        this.graf_i_btn = false;

        this.month_Data = false;
        this.month_Rank = false;
        this.yearly_Data = false;
        this.yearly_Rank = false;
        this.cheack_data = false;

        this.glaf_tab_active = false;
      },

      i_btnOpen: function() {
        this.i_btn = !this.i_btn;
        this.i_bg = !this.i_bg;
      },

      graf_i_btnOpen: function() {
        this.graf_i_btn = true;
        this.cheack_data = false;
      },
      graf_i_btnClose: function() {
        this.graf_i_btn = false;
        this.cheack_data = true;
      },



      cheack_data_month_RankOpen: function() {
        this.cheack_data = true;
        this.p_bg = true;
        this.month_Rank = true;
        this.month_Data = false;

        this.yearly_Data = false;
        this.yearly_Rank = false;
      },
      cheack_data_month_DataOpen: function() {
        this.cheack_data = true;
        this.p_bg = true;
        this.month_Data = true;
        this.month_Rank = false;

        this.yearly_Data = false;
        this.yearly_Rank = false;
      },
      cheack_data_yearly_DataOpen: function() {
        this.cheack_data = true;
        this.p_bg = true;
        this.yearly_Data = true;
        this.yearly_Rank = false;

        this.month_Data = false;
        this.month_Rank = false;
      },

      cheack_data_yearly_RankOpen: function() {
        this.cheack_data = true;
        this.p_bg = true;
        this.yearly_Rank = true;
        this.yearly_Data = false;

        this.month_Data = false;
        this.month_Rank = false;
      },

      glaf_tabOpen: function() {
        this.glaf_tab_active = !this.glaf_tab_active;
      },
    },
  }



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

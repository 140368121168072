<template>
    <div id="login">
        <header>
            <div class="header_inner">
                <h1>
                    <figure><img src="img/logo.png" alt="WAKUWAKU電子手帳" width="256" height="73"></figure>
                </h1>
            </div>
        </header>

        <!-- ここまでヘッダーの部分 -->
        <main id="">

                <div class="wrapper">
                    <div id="inner">
                        <h2><span></span>LOG IN<span></span></h2>

                        <section class="mail_login">
                            <dl>
                                <dt>メールアドレス</dt>
                                <dd><input v-model="email" type="text" class="required" placeholder="メールアドレス"></dd>

                                <dt>パスワード</dt>
                                <dd><input v-model="password" type="password" class="required" placeholder="パスワード"></dd>
                            </dl>
                            <p v-if="wrongPassword" style="text-align: center; color: red;">※メールアドレス、<br>またはパスワードが違います</p>
                            <button @click="login">LOG IN</button>
                        </section>
                        <!--
                        <section class="social_login">
                            <button onclick="">google</button>
                            <button onclick="">Facebook</button>
                        </section>
                        -->

                    </div>
                </div>

                <div class="sign_up">
                    <button @click="user_makeOpen">新規登録</button>
                    <button @click="forget_passOpen">パスワードを忘れた方はこちら</button>
                </div>


                <section class="register" v-show="new_edit">
                    <h2><span></span>USER MAKE<span></span></h2>
                    <button class="btn_close" @click="all_Close">×</button>
                    <dl>
                        <dt>利用登録</dt>
                        <dd>
                            <div id="check">
                                <div class="check">
                                    <input type="checkbox" @click="on_checkCheck">
                                    <p><a href="/login_direct" target="blank">利用規約</a>に同意</p>
                                </div>
                                <p v-show="addressError">そのメールアドレスは既に使われています。</p>
                                <input v-model="registerEmail" type="text" class="required" placeholder="メールアドレス">
                                <!--<button @click="loginsend_mailOpen">メール送信</button>-->

                                <button v-show="on_check_off" style="box-shadow: none;background: #ddd;" disabled="disabled">チェックを入れてください</button>
                                <button @click="loginsend_mailOpen" v-show="on_check">メール送信</button>
                            </div>
                        </dd>
                    </dl>
                    <!--
                    <dl>
                        <dt>
                            <span>or</span>
                            <span>LINK</span>
                            <span>Social Account</span>
                        </dt>
                        <dd>
                            <button onclick="">google</button>
                            <button onclick="">Facebook</button>
                        </dd>
                    </dl>-->
                </section>

                <section class="register forget" v-show="forget_pass">
                    <h2><span></span>LOG IN<span></span></h2>
                    <button class="btn_close" @click="all_Close">×</button>
                    <dl>
                        <dt>パスワードをお忘れの方</dt>
                        <dd>
                            <div>
                                <p>
                                  登録済みのメールアドレスを入力してください。<br>
                                  入力メールアドレスにメッセージが送信されます。
                                </p>
                                <p v-show="noEmailError" style="color:red;">そのメールアドレスは登録されていません。</p>
                                <input v-model="remindEmail" type="text" class="required" placeholder="メールアドレス">
                                <button @click="loginsend_mailOpen2">メール送信</button>
                            </div>
                        </dd>
                    </dl>
                </section>


                <section class="notice" v-show="notice">
                    <h2><span></span>NOTICE<span></span></h2>
                    <button class="btn_close" @click="all_Close">×</button>
                    <p>
                      入力されたメールアドレスに<br>
                      メールを送信いたしました。<br>
                      <br> 
                      送信されたメールから<br> 
                      登録してください。<br>
                      <br>
                      ※お使いの環境によっては<br>
                      「迷惑メール」等に<br>
                      振り分けられている場合があります。<br>
                      ご注意ください。<br>
                      <br>
                      ※10分経ってもメールが来ない<br>
                      場合は、再度登録し直してください。
                    </p>
                </section>

                <div id="bg_black" v-show="haikei" @click="all_Close"></div>
            </main>
      <Footer/>
    </div>
</template>

<script>
import Footer from '@/components/footer.vue'

// let bcrypt = require('bcrypt');
// bcrypt.hashSync(this.password, 10)
export default {
    components: {
      Footer
    },
  data: () => ({
    haikei: false,
    notice: false,
    new_edit: false,
    forget_pass: false,
    email: "",
    password: "",
    registerEmail: "",
    on_check_off: true,
    on_check: false,
    remindEmail: "",
    addressError: false,
    noEmailError: false,
    wrongPassword: false
  }),

  methods: {
    on_checkCheck: function() {
        this.on_check_off = !this.on_check_off;
        this.on_check = !this.on_check;
      },

    login: function() {
      const params = new URLSearchParams();
      params.append('email', this.email);
      params.append('password', this.password);

      this.axios.post('login', params)
      .then((response) => {
        if (response.data.accessToken) {
          this.$localStorage.set('accessToken', response.data.accessToken)
          this.$router.push({ name: 'calendar' })
        }
      })
      .catch((e) => {
        console.log(e);
        this.wrongPassword = true;
      })
    },

    user_makeOpen: function() {
      this.new_edit = true,
      this.haikei = true;
    },

    forget_passOpen: function() {
      this.forget_pass = true,
      this.haikei = true;
    },

    loginsend_mailOpen: function() {
      const params = new URLSearchParams();
      params.append('email', this.registerEmail);
      this.axios.post('register_email', params)
      .then((response) => {
        if (response.data) {
          this.forget_pass = false;
          this.new_edit = false;
          this.notice = true;
        } else {
          this.addressError = true
        }
      })
      .catch((e) => {
        console.log(e)
        this.forget_pass = false,
        this.new_edit = false;
        this.notice = true;
      })

    },

    loginsend_mailOpen2: function() {
      this.new_edit = false;

      // パスワード生成
      let password_base = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let length = 8
      let password = '';
      for (let i = 0; i < length; i++) {
        password += password_base.charAt(Math.floor(Math.random() * password_base.length));
      }
      const params = new URLSearchParams();
      params.append('email', this.remindEmail);
      params.append('password', password);

      // パスワード変更
      this.axios.post('reset', params)
      .then((response) => {
        console.log(response);
        if (response.data) {
          this.notice = true;
          this.noEmailError = false;
          this.forget_pass = false;
          this.axios.post('reset_email', params)
          .then((response) => {
            console.log(response);
          })
          .catch((e) => {
            console.log(e)
          })
        } else {
          this.notice = false;
          this.noEmailError = true;
          this.forget_pass = true;
        }
      })
      .catch((e) => {
        console.log(e)
      })

    },

    all_Close: function() {
      this.forget_pass = false,
      this.new_edit = false;
      this.notice = false;
      this.haikei = false;
    },
  }
}
</script>
<template>
    <div id="login">
        <header>
            <div class="header_inner">
                <h1>
                    <figure><img src="img/logo.png" alt="WAKUWAKU電子手帳" width="256" height="73"></figure>
                </h1>
            </div>
        </header>

        <!-- ここまでヘッダーの部分 -->
        <main id="login">

                <section class="section_input">
                    <h2><span></span>Input<span></span></h2>
                    <p>必要項目を入力してください</p>
                        <dl>
                            <dt>メールアドレス</dt>
                            <dd><input v-model="email" type="text" placeholder="メールアドレス" disabled></dd>
                            <dt>パスワード</dt>
                            <dd><input v-model="password" type="password" placeholder="パスワード"></dd>
                            <dd><input v-model="rePassword" type="password" placeholder="パスワード（もう一度）"></dd>
                            <dt>名前</dt>
                            <dd><input v-model="name" type="text" placeholder="名前"></dd>
                            <dt>年齢・性別</dt>
                            <dd><div>
                                <input v-model="age" type="text" placeholder="年齢">
                                <select v-model="gender" name="sex">
                                    <option value="1">男性</option>
                                    <option value="2">女性</option>
                                </select>
                            </div></dd>
                            <dt>活動地域</dt>
                            <dd>
                              <select v-model="area" placeholder="活動地域">
                                <option v-for="prefecture in prefectures" :value="prefecture" :key="prefecture">{{ prefecture }}</option>
                              </select>
                            </dd>
                        </dl>
                        <input v-if="this.password == this.rePassword && this.password !==''" type="submit" value="登録" @click="registry_CheckOpen">
                        <input v-else type="submit" value="登録" disabled>
                </section>

                <section class="notice_login" v-show="registry_check">
                    <h2><span></span>NOTICE<span></span></h2>
                    <button class="btn_back" @click="all_Close">←</button>

                    <dl>
                        <dt>メールアドレス</dt>
                        <dd><input v-model="email" type="text" disabled="disabled"></dd>
                        <dt>パスワード</dt>
                        <dd><input v-model="password" type="password" disabled="disabled" placeholder="パスワード"></dd>
                        <dt>名前</dt>
                        <dd><input v-model="name" type="text" disabled="disabled" placeholder="名前"></dd>
                        <dt>年齢・性別</dt>
                        <dd><div>
                            <input v-model="age" type="text" disabled="disabled" placeholder="年齢">
                            <select v-model="gender" name="sex" disabled="disabled">
                                <option value="1">男性</option>
                                <option value="2">女性</option>
                            </select>
                        </div></dd>
                        <dt>活動地域</dt>
                        <dd><input v-model="area" disabled="disabled" type="text" placeholder="活動地域"></dd>
                    </dl>
                    <p>以上でよろしいですか？</p>
                    <dl v-show="register_error" >
                      <dt>すでに登録されています。</dt>
                      <dt>下のボタンからログイン画面に移動してください。</dt>
                      <a href="https://wakuwaku-suchi.com/login"><input style="margin:15px auto;" type="submit" value="ログイン画面へ" ></a>
                    </dl>
                    <input @click="register" type="submit" value="決定" v-show="!register_error">

            </section>

            <div id="bg_black" v-show="haikei" @click="all_Close"></div>
            </main>
    </div>
</template>

<script>
export default {
  data: () => ({
      haikei: false,
      registry_check: false,
      email: "",
      password: "",
      rePassword: "",
      name: "",
      age: "",
      gender: 1,
      area: "",
      register_error: false,
      prefectures: [
        null,
        '北海道',
        '青森県',
        '岩手県',
        '宮城県',
        '秋田県',
        '山形県',
        '福島県',
        '茨城県',
        '栃木県',
        '群馬県',
        '埼玉県',
        '千葉県',
        '東京都',
        '神奈川県',
        '新潟県',
        '富山県',
        '石川県',
        '福井県',
        '山梨県',
        '長野県',
        '岐阜県',
        '静岡県',
        '愛知県',
        '三重県',
        '滋賀県',
        '京都府',
        '大阪府',
        '兵庫県',
        '奈良県',
        '和歌山県',
        '鳥取県',
        '島根県',
        '岡山県',
        '広島県',
        '山口県',
        '徳島県',
        '香川県',
        '愛媛県',
        '高知県',
        '福岡県',
        '佐賀県',
        '長崎県',
        '熊本県',
        '大分県',
        '宮崎県',
        '鹿児島県',
        '沖縄県',
        '海外',
      ],
  }),
  mounted() {
    this.email = this.$route.query.email;
  },
  methods: {
    register: function() {
      const params = new URLSearchParams();
      params.append('email', this.email);
      params.append('password', this.password);
      params.append('name', this.name);
      params.append('age', this.age);
      params.append('gender', this.gender);
      params.append('area', this.area);

      this.axios.post('register', params)
      .then((response) => {
        console.log(response);
        this.register_error = false;
        if (response.data) {
          this.$localStorage.set('accessToken', response.data.accessToken)
          this.$router.push({ name: 'calendar' })
        } else {
          this.register_error = true;
        }
      })
      .catch((e) => {
        console.log(e)
      })
    },
    registry_CheckOpen: function() {
      this.registry_check = true,
      this.haikei = true;
    },
    all_Close: function() {
      this.registry_check = false;
      this.haikei = false;
    },
  }
}


</script>
<template>

  <div id="btn_click">

    <transition name="fade">
      <section id="entry"  v-show="monthly_bottom_btn">
        <transition name="fade">
          <div class=entry_position v-show="monthly_bottom_btn_input">
            <div class="select_tab">
              <button @click="monthly_bottom_btnOpen_easy()">EASY</button>
              <button class="active">FULL</button>
            </div>

            <div class="entry_inner">
              <dl class="title">
                <dt>目標入力</dt>
                <dd>PLAN</dd>
              </dl>
              <button class="btn_close" type="button" @click="monthly_bottom_btnClose()">×</button>

              <!--<div class="type_select">
                <button class="active">PLAN</button>
                <button @click="monthly_bottom_btnOpen_do(do_day)">DO</button>
              </div>-->

              <div class="data_select">
                <span class="select_arrow"><select name="year" v-model="input_day.input_year">
                  <option v-for="year in years" :key="year">{{ year }}</option>
                </select></span>
                <span>/</span>
                <span class="select_arrow"><select name="month" v-model="input_day.input_month">
                  <option v-for="month in months" :key="month">{{ month }}</option>
                </select></span>
              </div>

              
              <div class="input">

                <div class="input_scroll">
                  <dl>
                    <dt>技術売上</dt>
                    <dd><input type="tel" placeholder="技術売上" v-model="input_techSales" v-bind:class="{text_error : input_techSales === '' || String(input_techSales).match(/[^0-9]+/)}" v-on:click="change_input_without_total"></dd>
                  </dl>
                  <dl>
                    <dt>商品売上</dt>
                    <dd><input type="tel" placeholder="商品売上" v-model="input_itemSales" v-bind:class="{text_error : input_itemSales === '' || String(input_itemSales).match(/[^0-9]+/)}" v-on:click="change_input_without_total"></dd>
                  </dl>
                  <dl>
                    <dt>その他売上</dt>
                    <dd><input type="tel" placeholder="その他売上" v-model="input_otherSales" v-bind:class="{text_error : input_otherSales === '' || String(input_otherSales).match(/[^0-9]+/)}" v-on:click="change_input_without_total"></dd>
                  </dl>
                  <dl>
                    <dt>総客数</dt>
                    <dd><input type="tel" placeholder="総客数" v-model="input_numberOfCustomers" v-bind:class="{text_error : input_numberOfCustomers === '' || String(input_numberOfCustomers).match(/[^0-9]+/)}"></dd>
                  </dl>
                  <dl>
                    <dt>仕事時間</dt>
                    <dd><input type="tel" placeholder="仕事時間" v-model="input_time" v-bind:class="{text_error : input_time === '' || String(input_time).match(/[^0-9]+/)}"></dd>
                  </dl>
                </div>

                <div class="input_head">
                  <h3>総売上</h3>
                  <button  @click="i_btnOpen()">i</button>
                </div>
                <div class="w80">
                  <p>{{input_display_totalSale.toLocaleString()}}</p>
                  <!--<input type="text" placeholder="総売上" v-model="input_display_totalSale" disabled>-->
                </div>

              </div>
              <p class="form_alert" v-if="full_input_number_error_flag === 1">半角数字以外は入力しないでください。</p>
              <p class="form_alert" v-if="full_input_null_error_flag === 1">データを全て入力してください。</p>
              <div class="input_end_btn">
                <button v-bind:disabled="full_input_null_error_flag == 1 || full_input_number_error_flag == 1" v-bind:class="{disabled : full_input_null_error_flag == 1 || full_input_number_error_flag == 1}" v-on:click="set_full_input">決定</button>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div class=entry_position v-show="monthly_bottom_btn_input_easy">
            <div class="select_tab">
              <button class="active">EASY</button>
              <button @click="monthly_bottom_btnOpen(input_day.input_year, input_day.input_month)">FULL</button>
            </div>

            <div class="entry_inner">
              <dl class="title">
                <dt>目標入力</dt>
                <dd>PLAN</dd>
              </dl>
              <button class="btn_close" type="button" @click="monthly_bottom_btnClose()">×</button>

              <!--<div class="type_select">
                <button class="active">PLAN</button>
                <button @click="monthly_bottom_btnOpen_do_easy">DO</button>
              </div>-->

              <div class="data_select">
                <span class="select_arrow"><select name="year" v-model="input_day.input_year">
                  <option v-for="year in years" :key="year">{{ year }}</option>
                </select></span>
                <span>/</span>
                <span class="select_arrow"><select name="month" v-model="input_day.input_month">
                  <option v-for="month in months" :key="month">{{ month }}</option>
                </select></span>
              </div>

              <div class="input_head">
                <h3>総売上</h3>
                <button  @click="i_btnOpen()">i</button>
              </div>
              <div class="input">
                <div class="w80">
                  <input type="tel" placeholder="総売上" v-model="input_totalSales" v-bind:class="{text_error : input_totalSales === '' || String(input_totalSales).match(/[^0-9]+/)}">
                </div>
              </div>
              <p class="form_alert" v-if="easy_input_number_error_flag === 1">半角数字以外は入力しないでください。</p>
              <p class="form_alert" v-if="easy_input_null_error_flag === 1">データを全て入力してください。</p>
              <div class="input_end_btn">
                <button v-bind:disabled="easy_input_null_error_flag == 1 || easy_input_number_error_flag == 1" v-on:click="set_easy_input">決定</button>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div class=entry_position v-show="monthly_bottom_btn_do">
            <div class="select_tab">
              <button @click="monthly_bottom_btnOpen_do_easy()">EASY</button>
              <button class="active">FULL</button>
            </div>
      
            <div class="entry_inner">
              <dl class="title">
                <dt>実績入力</dt>
                <dd>DO</dd>
              </dl>
              <button class="btn_close" type="button"  @click="monthly_bottom_btnClose()">×</button>
    
              <!--<div class="type_select">
                <button @click="monthly_bottom_btnOpen(input_day.input_year, input_day.input_month)">PLAN</button>
                <button class="active">DO</button>
              </div>-->
    
              <div class="data_select">
                <input type="date" v-model="do_day">
              </div>
    
              
              <div class="input">

                <div class="input_scroll">
                  <dl>
                    <dt>技術売上</dt>
                    <dd><input type="tel" placeholder="技術売上" v-model="do_techSales" v-bind:class="{text_error : do_techSales === '' || String(do_techSales).match(/[^0-9]+/)}" v-on:click="change_input_without_total"></dd>
                  </dl>
                  <dl>
                    <dt>商品売上</dt>
                    <dd><input type="tel" placeholder="商品売上" v-model="do_itemSales" v-bind:class="{text_error : do_itemSales === '' || String(do_itemSales).match(/[^0-9]+/)}" v-on:click="change_input_without_total"></dd>
                  </dl>
                  <dl>
                    <dt>その他売上</dt>
                    <dd><input type="tel" placeholder="その他売上" v-model="do_otherSales" v-bind:class="{text_error : do_otherSales === '' || String(do_otherSales).match(/[^0-9]+/)}" v-on:click="change_input_without_total"></dd>
                  </dl>
                  <dl>
                    <dt>総客数</dt>
                    <dd><input type="tel" placeholder="総客数" v-model="do_numberOfCustomers" v-bind:class="{text_error : do_numberOfCustomers === '' || String(do_numberOfCustomers).match(/[^0-9]+/)}"></dd>
                  </dl>
                  <dl>
                    <dt>仕事時間</dt>
                    <dd><input type="tel" placeholder="仕事時間" v-model="do_time" v-bind:class="{text_error : do_time === '' || String(do_time).match(/[^0-9]+/)}"></dd>
                  </dl>
                </div>
                <div class="input_head">
                  <h3>総売上</h3>
                  <button @click="i_btnDoOpen()">i</button>
                </div>
                <div class="w80">
                    <p>{{do_display_totalSale.toLocaleString()}}</p>
                    <!--<input type="text" placeholder="総売上" v-model="do_display_totalSale" disabled>-->
                </div>
              </div>
              <p class="form_alert" v-if="full_do_number_error_flag === 1">半角数字以外は入力しないでください。</p>
              <p class="form_alert" v-if="full_do_null_error_flag === 1">データを全て入力してください。</p>
              <div class="input_end_btn">
                <button v-bind:disabled="full_do_null_error_flag == 1 || full_do_number_error_flag == 1" v-bind:class="{disabled : full_do_null_error_flag == 1 || full_do_number_error_flag == 1}" v-on:click="set_full_do">決定</button>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div class=entry_position v-show="monthly_bottom_btn_do_easy">
            <div class="select_tab">
              <button class="active">EASY</button>
              <button @click="monthly_bottom_btnOpen_do(do_day)">FULL</button>
            </div>
            <div class="entry_inner">
              <dl class="title">
                <dt>実績入力</dt>
                <dd>DO</dd>
              </dl>
              <button class="btn_close" type="button"  @click="monthly_bottom_btnClose()">×</button>

              <!--<div class="type_select">
                <button @click="monthly_bottom_btnOpen_easy">PLAN</button>
                <button class="active">DO</button>
              </div>-->
    
              <div class="data_select">
                <input type="date" v-model="do_day">
              </div>

              <div class="input_head">
                <h3>総売上</h3>
                <button @click="i_btnDoOpen()">i</button>
              </div>
              <div class="input">
                <div class="w80">
                  <input type="tel" placeholder="総売上" v-model="do_totalSales" v-bind:class="{text_error : do_totalSales === '' || String(do_totalSales).match(/[^0-9]+/)}">
                </div>
              </div>
              <p class="form_alert" v-if="easy_do_number_error_flag === 1">半角数字以外は入力しないでください。</p>
              <p class="form_alert" v-if="easy_do_null_error_flag === 1">データを全て入力してください。</p>
              <div class="input_end_btn">
                <button v-bind:disabled="easy_do_null_error_flag == 1 || easy_do_number_error_flag == 1" v-on:click="set_easy_do">決定</button>
              </div>
            </div>
          </div>
        </transition>
      </section>
    </transition>

    <transition name="fade">
      <div id="entry_outer" v-show="p_bg" @click="monthly_bottom_btnClose()" :class="{'p_bg_active': p_bg_active,'i_bg': i_bg}">
      </div>
    </transition>


    <transition name="fade">
      <div id="cheack_data_outer" v-show="cheack_data">

        <transition name="fade">
          <section id="cheack_data" v-show="month_Rank">
            <dl><dt>月間実績比較 -ランク-</dt><dd>Input -Rank-</dd></dl>
            <button class="btn_close" @click="monthly_bottom_btnClose()">×</button>
            <div class="type_select">
              <button @click="cheack_data_yearly_RankOpen()">Yearly</button>
              <button class="active">Monthly</button>
            </div>
            <div class="view_select">
              <div>
                <span class="select_arrow"><select name="year" v-model="input_day.input_year">
                  <option v-for="year in years" :key="year">{{ year }}</option>
                </select></span>
                <span>/</span>
                <span class="select_arrow"><select name="month" v-model="input_day.input_month">
                  <option v-for="month in months" :key="month">{{ month }}</option>
                </select></span>
              </div>
              <button @click="graf_i_btnOpen()">i</button>
            </div>
            <figure></figure>
            <table class="tab">
              <tr>
                <th></th>
                <th>累計</th>
                <th>日平均</th>
                <th>達成率</th>
              </tr>
              <tr>
                <td>
                  <button @click="glaf_tabOpen()" :class="{'is-glaf_tab_active': glaf_tab_active}">総売上</button>
                </td>
                <td>{{ monthly_records_data.totalSales.toLocaleString() }}</td>
                <td>{{ monthly_total_per_day.toLocaleString() }}</td>
                <td>{{ monthly_total_record_per_goal }}％</td>
              </tr>
              <!---->
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>技術売上</td>
                <td>{{ monthly_records_data.techSales.toLocaleString() }}</td>
                <td>{{ monthly_tech_per_day.toLocaleString() }}</td>
                <td>{{ monthly_tech_record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>商品売上</td>
                <td>{{ monthly_records_data.itemSales.toLocaleString() }}</td>
                <td>{{ monthly_item_per_day.toLocaleString() }}</td>
                <td>{{ monthly_item_record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>その他売上</td>
                <td>{{ monthly_records_data.otherSales.toLocaleString() }}</td>
                <td>{{ monthly_other_per_day.toLocaleString() }}</td>
                <td>{{ monthly_other_record_per_goal }}％</td>
              </tr>
            </table>
          </section>
        </transition>
              <!---->


        <transition name="fade">
          <section id="cheack_data" v-show="month_Data">
            <dl><dt>月間実績比較 -データ-</dt><dd>Input -Data-</dd></dl>
            <button class="btn_close" @click="monthly_bottom_btnClose()">×</button>
            <div class="type_select">
              <button @click="cheack_data_yearly_DataOpen()">Yearly</button>
              <button class="active">Monthly</button>
            </div>
            <div class="view_select">
              <div>
                <span class="select_arrow"><select name="year" v-model="input_day.input_year">
                  <option v-for="year in years" :key="year">{{ year }}</option>
                </select></span>
                <span>/</span>
                <span class="select_arrow"><select name="month" v-model="input_day.input_month">
                  <option v-for="month in months" :key="month">{{ month }}</option>
                </select></span>
              </div>
              <button @click="graf_i_btnOpen()">i</button>
            </div>
            <figure><MonthlyGraph ref="monthly_graph" :year="input_day.input_year" :month="input_day.input_month"></MonthlyGraph></figure>
            <table>
              <tr>
                <th></th>
                <th>累計</th>
                <th>日平均</th>
                <th>達成率</th>
              </tr>
              <tr>
                <td>
                  <button @click="glaf_tabOpen()" :class="{'is-glaf_tab_active': glaf_tab_active}">総売上</button>
                </td>
                <td>
                  <p v-if="monthly_records_data.totalSales">{{ Number(monthly_records_data.totalSales).toLocaleString() }}</p>
                  <p v-else>-</p>
                </td>
                <td>{{ monthly_total_per_day }}</td>
                <td>{{ monthly_total_record_per_goal }}％</td>
              </tr>
              <!---->
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>技術売上</td>
                <td>
                  <p v-if="monthly_records_data.techSales">{{ Number(monthly_records_data.techSales).toLocaleString() }}</p>
                  <p v-else>-</p>
                </td>
                <td>{{ monthly_tech_per_day }}</td>
                <td>{{ monthly_tech_record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>商品売上</td>
                <td>
                  <p v-if="monthly_records_data.itemSales">{{ Number(monthly_records_data.itemSales).toLocaleString() }}</p>
                  <p v-else>-</p>
                </td>
                <td>{{ monthly_item_per_day }}</td>
                <td>{{ monthly_item_record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>その他売上</td>
                <td>
                  <p v-if="monthly_records_data.otherSales">{{ Number(monthly_records_data.otherSales).toLocaleString() }}</p>
                  <p v-else>-</p>
                </td>
                <td>{{ monthly_other_per_day }}</td>
                <td>{{ monthly_other_record_per_goal }}％</td>
              </tr>
              <!---->

              <tr>
                <td>
                  <button @click="glaf_tabOpen()" :class="{'is-glaf_tab_active': glaf_tab_active}">客数</button>
                </td>
                <td>
                  <p v-if="monthly_records_data.numberOfCustomers">{{ Number(monthly_records_data.numberOfCustomers).toLocaleString() }}</p>
                  <p v-else>-</p>
                </td>
                <td>{{ monthly_customer_per_day }}</td>
                <td>{{ monthly_customer_record_per_goal }}％</td>
              </tr>
              <!---->
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>総客単価</td>
                <td>{{ monthly_total_per_customer }}</td>
                <td>{{ monthly__total_per_customer__per_day }}</td>
                <td>{{ monthly__total_per_customer__record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>技術客単価</td>
                <td>{{ monthly_tech_per_customer }}</td>
                <td>{{ monthly__tech_per_customer__per_day }}</td>
                <td>{{ monthly__tech_per_customer__record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>商品客単価</td>
                <td>{{ monthly_item_per_customer }}</td>
                <td>{{ monthly__item_per_customer__per_day }}</td>
                <td>{{ monthly__item_per_customer__record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>その他客単価</td>
                <td>{{ monthly_other_per_customer }}</td>
                <td>{{ monthly__other_per_customer__per_day }}</td>
                <td>{{ monthly__other_per_customer__record_per_goal }}％</td>
              </tr>
              <!---->


              <tr>
                <td>
                  <button @click="glaf_tabOpen()" :class="{'is-glaf_tab_active': glaf_tab_active}">仕事時間</button>
                </td>
                <td>
                  <p v-if="monthly_records_data.time">{{ Number(monthly_records_data.time).toLocaleString() }}</p>
                  <p v-else>-</p>
                </td>
                <td>{{ monthly_time_per_day }}</td>
                <td>{{ monthly_time_record_per_goal }}％</td>
              </tr>
              <!---->
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>営業日数</td>
                <td>
                  <p v-if="monthly_records_data.workingDays">{{ Number(monthly_records_data.workingDays).toLocaleString() }}</p>
                  <p v-else>-</p>
                </td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>総時間単価</td>
                <td>{{ monthly_total_per_time }}</td>
                <td>{{ monthly__total_per_time__per_day }}</td>
                <td>{{ monthly__total_per_time__record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>技術時間単価</td>
                <td>{{ monthly_tech_per_time }}</td>
                <td>{{ monthly__tech_per_time__per_day }}</td>
                <td>{{ monthly__tech_per_time__record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>商品時間単価</td>
                <td>{{ monthly_item_per_time }}</td>
                <td>{{ monthly__item_per_time__per_day }}</td>
                <td>{{ monthly__item_per_time__record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>その他時間単価</td>
                <td>{{ monthly_other_per_time }}</td>
                <td>{{ monthly__other_per_time__per_day }}</td>
                <td>{{ monthly__other_per_time__record_per_goal }}％</td>
              </tr>
              <!---->

            </table>
            <div class="select_tab">
              <button class="active">DATA</button>
              <button @click="rank_alertOpen()">RANK</button>
            </div>
          </section>
        </transition>

        
        <transition name="fade">
          <section id="cheack_data" v-show="yearly_Data"> <!-- y data -->
            <dl><dt>年間実績比較 -データ-</dt><dd>Input -Data-</dd></dl>
            <button class="btn_close" @click="monthly_bottom_btnClose()">×</button>
            <div class="type_select">
              <button class="active">Yearly</button>
              <button @click="cheack_data_month_DataOpen(input_day.input_year,input_day.input_month)">Monthly</button>
            </div>
            <div class="view_select">
              <div>
                <span class="select_arrow"><select name="year" v-model="input_day.input_year">
                  <option v-for="year in years" :key="year">{{ year }}</option>
                </select></span>
              </div>
              <button @click="graf_i_year_btnOpen()">i</button>
            </div>
            <figure><YearlyGraph  ref="yearly_graph"  :year="input_day.input_year"></YearlyGraph></figure>
            <table>
              <tr>
                <th></th>
                <th>累計</th>
                <th>日平均</th>
                <th>達成率</th>
              </tr>
              <tr>
                <td>
                  <button @click="glaf_tabOpen()" :class="{'is-glaf_tab_active': glaf_tab_active}">総売上</button>
                </td>
                <td>
                  <p v-if="yearly_records_data.totalSales">{{ Number(yearly_records_data.totalSales).toLocaleString() }}</p>
                  <p v-else>-</p>
                </td>
                <td>{{ yearly_total_per_day }}</td>
                <td>{{ yearly_total_record_per_goal }}％</td>
              </tr>
              <!---->
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>技術売上</td>
                <td>
                  <p v-if="yearly_records_data.techSales">{{ Number(yearly_records_data.techSales).toLocaleString() }}</p>
                  <p v-else>-</p>
                </td>
                <td>{{ yearly_tech_per_day }}</td>
                <td>{{ yearly_tech_record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>商品売上</td>
                <td>
                  <p v-if="yearly_records_data.itemSales">{{ Number(yearly_records_data.itemSales).toLocaleString() }}</p>
                  <p v-else>-</p>
                </td>
                <td>{{ yearly_item_per_day }}</td>
                <td>{{ yearly_item_record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>その他売上</td>
                <td>
                  <p v-if="yearly_records_data.otherSales">{{ Number(yearly_records_data.otherSales).toLocaleString() }}</p>
                  <p v-else>-</p>
                </td>
                <td>{{ yearly_other_per_day }}</td>
                <td>{{ yearly_other_record_per_goal }}％</td>
              </tr>
              <!---->

              <tr>
                <td>
                  <button @click="glaf_tabOpen()" :class="{'is-glaf_tab_active': glaf_tab_active}">客数</button>
                </td>
                <td>
                  <p v-if="yearly_records_data.numberOfCustomers">{{ Number(yearly_records_data.numberOfCustomers).toLocaleString() }}</p>
                  <p v-else>-</p>
                </td>
                <td>{{ yearly_customer_per_day }}</td>
                <td>{{ yearly_customer_record_per_goal }}％</td>
              </tr>
              <!---->
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>総客単価</td>
                <td>{{ yearly_total_per_customer }}</td>
                <td>{{ yearly__total_per_customer__per_day }}</td>
                <td>{{ yearly__total_per_customer__record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>技術客単価</td>
                <td>{{ yearly_tech_per_customer }}</td>
                <td>{{ yearly__tech_per_customer__per_day }}</td>
                <td>{{ yearly__tech_per_customer__record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>商品客単価</td>
                <td>{{ yearly_item_per_customer }}</td>
                <td>{{ yearly__item_per_customer__per_day }}</td>
                <td>{{ yearly__item_per_customer__record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>その他客単価</td>
                <td>{{ yearly_other_per_customer }}</td>
                <td>{{ yearly__other_per_customer__per_day }}</td>
                <td>{{ yearly__other_per_customer__record_per_goal }}％</td>
              </tr>
              <!---->


              <tr>
                <td>
                  <button @click="glaf_tabOpen()" :class="{'is-glaf_tab_active': glaf_tab_active}">仕事時間</button>
                </td>
                <td>
                  <p v-if="yearly_records_data.time">{{ Number(yearly_records_data.time).toLocaleString() }}</p>
                  <p v-else>-</p>
                </td>
                <td>{{ yearly_time_per_day }}</td>
                <td>{{ yearly_time_record_per_goal }}％</td>
              </tr>
              <!---->
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>営業日数</td>
                <td>
                  <p v-if="yearly_records_data.workingDays">{{ Number(yearly_records_data.workingDays).toLocaleString() }}</p>
                  <p v-else>-</p>
                </td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>総時間単価</td>
                <td>{{ yearly_total_per_time }}</td>
                <td>{{ yearly__total_per_time__per_day }}</td>
                <td>{{ yearly__total_per_time__record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>技術時間単価</td>
                <td>{{ yearly_tech_per_time }}</td>
                <td>{{ yearly__tech_per_time__per_day }}</td>
                <td>{{ yearly__tech_per_time__record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>商品時間単価</td>
                <td>{{ yearly_item_per_time }}</td>
                <td>{{ yearly__item_per_time__per_day }}</td>
                <td>{{ yearly__item_per_time__record_per_goal }}％</td>
              </tr>
              <tr class="hide_cell hide_active" v-show="glaf_tab_active">
                <td>その他時間単価</td>
                <td>{{ yearly_other_per_time }}</td>
                <td>{{ yearly__other_per_time__per_day }}</td>
                <td>{{ yearly__other_per_time__record_per_goal }}％</td>
              </tr>
              <!---->

            </table>
            <div class="select_tab">
              <button class="active">DATA</button>
              <button @click="rank_alertOpen()">RANK</button>
            </div>
          </section>
        </transition>

      </div>
    </transition>

    <transition name="fade">
      <section id="rank_alert" v-if="rank_alert">
        <div>
          <p><span>ランク機能</span>は<br>今後のアップデートで実装予定です</p>
          <button @click="rank_alertClose()">閉じる</button>
        </div>
      </section>

    </transition>

    <transition name="fade"><!-- 月間の比較 -->
      <section id="historical_data" v-show="graf_i_btn">
        <h2>
          {{ input_day.input_year }}
          <span>-Historical Data-</span>
        </h2>
        <!--<select class="type_select">
            <option value="">累計</option>
            <option value="">全国平均</option>
        </select>-->
        <button class="btn_close" @click="graf_i_btnClose()">×</button>

        <div class="scroll_table">
        <table>
          <tbody>
            <tr class="day">
              <th class="month_print">{{ input_day.input_month }}/</th>
              <td v-for="day in days_of_month" :key="day"><p>{{ day }}日</p></td>
            </tr>
            <tr>
              <th class="t_l">総売上</th>
              <td v-for="(infor_monthly_data, index) in information_monthly_data" :key="index">
                  <p v-if="infor_monthly_data.totalSales">{{ Number(infor_monthly_data.totalSales).toLocaleString() }}</p>
                  <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>技術売上</th>
              <td v-for="(infor_monthly_data, index) in information_monthly_data" :key="index">
                <p v-if="infor_monthly_data.techSales">{{ Number(infor_monthly_data.techSales).toLocaleString() }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>商品売上</th>
              <td v-for="(infor_monthly_data, index) in information_monthly_data" :key="index">
                <p v-if="infor_monthly_data.itemSales">{{ Number(infor_monthly_data.itemSales).toLocaleString() }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>その他売上</th>
              <td v-for="(infor_monthly_data, index) in information_monthly_data" :key="index">
                <p v-if="infor_monthly_data.otherSales">{{ Number(infor_monthly_data.otherSales).toLocaleString() }}</p>
                <p v-else>-</p>
              </td>
            </tr>

            <tr>
              <th class="t_l">総客数</th>
              <td v-for="(infor_monthly_data, index) in information_monthly_data" :key="index">
                <p v-if="infor_monthly_data.numberOfCustomers">{{ Number(infor_monthly_data.numberOfCustomers).toLocaleString() }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>総客単価</th>
              <td v-for="(infor_monthly_data, index) in information_monthly_data" :key="index">{{ unit_round_method(infor_monthly_data.totalSales, infor_monthly_data.numberOfCustomers) }}</td>
            </tr>
            <tr>
              <th>技術客単価</th>
              <td v-for="(infor_monthly_data, index) in information_monthly_data" :key="index">{{ unit_round_method(infor_monthly_data.techSales, infor_monthly_data.numberOfCustomers) }}</td>
            </tr>
            <tr>
              <th>商品客単価</th>
              <td v-for="(infor_monthly_data, index) in information_monthly_data" :key="index">{{ unit_round_method(infor_monthly_data.itemSales, infor_monthly_data.numberOfCustomers) }}</td>
            </tr>
            <tr>
              <th>その他客単価</th>
              <td v-for="(infor_monthly_data, index) in information_monthly_data" :key="index">{{ unit_round_method(infor_monthly_data.otherSales, infor_monthly_data.numberOfCustomers) }}</td>
            </tr>

            <tr>
              <th class="t_l">仕事時間</th>
              <td v-for="(infor_monthly_data, index) in information_monthly_data" :key="index">
                <p v-if="infor_monthly_data.time">{{ Number(infor_monthly_data.time).toLocaleString() }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>総時間単価</th>
              <td v-for="(infor_monthly_data, index) in information_monthly_data" :key="index">{{ unit_round_method(infor_monthly_data.totalSales, infor_monthly_data.time) }}</td>
            </tr>
            <tr>
              <th>技術時間単価</th>
              <td v-for="(infor_monthly_data, index) in information_monthly_data" :key="index">{{ unit_round_method(infor_monthly_data.techSales, infor_monthly_data.time) }}</td>
            </tr>
            <tr>
              <th>商品時間単価</th>
              <td v-for="(infor_monthly_data, index) in information_monthly_data" :key="index">{{ unit_round_method(infor_monthly_data.itemSales, infor_monthly_data.time) }}</td>
            </tr>
            <tr>
              <th>その他時間単価</th>
              <td v-for="(infor_monthly_data, index) in information_monthly_data" :key="index">{{ unit_round_method(infor_monthly_data.otherSales, infor_monthly_data.time) }}</td>
            </tr>
          </tbody>
        </table>
        
        </div>
        <div  @click="sendMailmonthly()" class="btn_outer">
          <button>Send Mail</button>
        </div>

      </section>
    </transition>

    <transition name="fade"><!-- 年間の比較 -->
      <section id="historical_data" v-show="graf_i_year_btn">
        <h2>
          {{ input_day.input_year }}
          <span>-Historical Data-</span>
        </h2>
        <!--<select class="type_select">
            <option value="">累計</option>
            <option value="">全国平均</option>
        </select>-->
        <button class="btn_close" @click="graf_i_btnClose()">×</button>

        <div class="scroll_table">
        <table>
          <tbody>
            <tr class="day">
              <th class="month_print"></th>
              <td v-for="month in months" :key="month">{{ month }}月</td>
            </tr>
            <tr>
              <th class="t_l">総売上</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.totalSales">{{ Number(infor_yearly_data.totalSales).toLocaleString() }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>技術売上</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.techSales">{{ Number(infor_yearly_data.techSales).toLocaleString() }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>商品売上</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.itemSales">{{ Number(infor_yearly_data.itemSales).toLocaleString() }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>その他売上</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.otherSales">{{ Number(infor_yearly_data.otherSales).toLocaleString() }}</p>
                <p v-else>-</p>
              </td>
            </tr>

            <tr>
              <th class="t_l">総客数</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.numberOfCustomers">{{ Number(infor_yearly_data.numberOfCustomers).toLocaleString() }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>総客単価</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.numberOfCustomers">{{ unit_round_method(infor_yearly_data.totalSales, infor_yearly_data.numberOfCustomers) }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>技術客単価</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.numberOfCustomers">{{ unit_round_method(infor_yearly_data.totalSales, infor_yearly_data.numberOfCustomers) }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>商品客単価</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.numberOfCustomers">{{ unit_round_method(infor_yearly_data.itemSales, infor_yearly_data.numberOfCustomers) }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>その他客単価</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.numberOfCustomers">{{ unit_round_method(infor_yearly_data.otherSales, infor_yearly_data.numberOfCustomers) }}</p>
                <p v-else>-</p>
              </td>
            </tr>

            <tr>
              <th class="t_l">仕事時間</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.time">{{ Number(infor_yearly_data.time).toLocaleString() }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>営業日数</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.workingDays">{{ infor_yearly_data.workingDays }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>１日売上</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.numberOfCustomers">{{ unit_round_method(infor_yearly_data.totalSales, infor_yearly_data.workingDays) }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>総時間単価</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.time">{{ unit_round_method(infor_yearly_data.totalSales, infor_yearly_data.time) }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>技術時間単価</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.time">{{ unit_round_method(infor_yearly_data.techSales, infor_yearly_data.time) }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>商品時間単価</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.time">{{ unit_round_method(infor_yearly_data.itemSales, infor_yearly_data.time) }}</p>
                <p v-else>-</p>
              </td>
            </tr>
            <tr>
              <th>その他時間単価</th>
              <td v-for="(infor_yearly_data, index) in information_yearly_data" :key="index">
                <p v-if="infor_yearly_data.time">{{ unit_round_method(infor_yearly_data.otherSales, infor_yearly_data.time) }}</p>
                <p v-else>-</p>
              </td>
            </tr>
          </tbody>
        </table>
        
        </div>
        <div @click="sendMailYearly()" class="btn_outer">
          <button>Send Mail</button>
        </div>

      </section>
    </transition>

    <transition name="fade">
      <section id="information" v-show="i_btn">

        <!-- iボタン PLAN -->
        <h2 v-show="i_btn_plan">-売上目標-</h2>
        <button class="btn_close" type="button" v-show="i_btn_plan" @click="i_btnOpen()">×</button>
        <div class="scroll_table plan" v-show="i_btn_plan">
          <dl>
            <dt>{{ input_day.input_year }}</dt>
            <dd>
              <table>
                <tr>
                  <th></th>
                  <td v-for="month in months" :key="month" v-bind:class="{ current_month : input_day.input_year + '-' + ('00' + String(month)).slice(-2) == only_today_year_month }">{{ month }}月</td>
                </tr>

                <tr>
                  <th>総売上</th>
                  <td v-for="(information_goal_data, index) in information_goal_datas" :key="index" v-bind:class="{ current_month : information_goal_data.month ==  only_today_year_month }">
                    <p v-if="information_goal_data.totalSales">{{ Number(information_goal_data.totalSales).toLocaleString() }}</p>
                    <p v-else>-</p>
                  </td>
                </tr>
                <tr>
                  <th>技術売上</th>
                  <td v-for="(information_goal_data, index) in information_goal_datas" :key="index" v-bind:class="{ current_month : information_goal_data.month == only_today_year_month }">
                    <p v-if="information_goal_data.techSales">{{ Number(information_goal_data.techSales).toLocaleString() }}</p>
                    <p v-else>-</p>
                  </td>
                </tr>
                <tr>
                  <th>商品売上</th>
                  <td v-for="(information_goal_data, index) in information_goal_datas" :key="index" v-bind:class="{ current_month : information_goal_data.month == only_today_year_month }">
                    <p v-if="information_goal_data.itemSales">{{ Number(information_goal_data.itemSales).toLocaleString() }}</p>
                    <p v-else>-</p>
                  </td>
                </tr>
                <tr>
                  <th>その他売上</th>
                  <td v-for="(information_goal_data, index) in information_goal_datas" :key="index" v-bind:class="{ current_month : information_goal_data.month == only_today_year_month }">
                    <p v-if="information_goal_data.otherSales">{{ Number(information_goal_data.otherSales).toLocaleString() }}</p>
                    <p v-else>-</p>
                  </td>
                </tr>
                <tr>
                  <th>総客数</th>
                  <td v-for="(information_goal_data, index) in information_goal_datas" :key="index" v-bind:class="{ current_month : information_goal_data.month == only_today_year_month }">
                    <p v-if="information_goal_data.numberOfCustomers">{{ Number(information_goal_data.numberOfCustomers).toLocaleString() }}</p>
                    <p v-else>-</p>
                  </td>
                </tr>
              </table>

            </dd>
          </dl>
        </div>

        <!-- iボタン DO -->
        <h2 v-show="i_btn_do">-売上実績-</h2>
        <button class="btn_close" type="button" v-show="i_btn_do" @click="i_btnDoOpen()">×</button>

        <div class="scroll_table do" v-show="i_btn_do">
          <dl>
            <dt>{{ input_day.input_year }}</dt>
            <dd>
              <table>
                <tr>
                  <th>{{ input_day.input_month }}月</th>
                  <td v-for="day in days_of_month" :key="day" v-bind:class="{ current_month : input_day.input_year + '-' + ('00' + String(input_day.input_month)).slice(-2) + '-' + ('00' + String(day)).slice(-2) == today_yyyy_mm_dd}">{{ day }}日</td>
                </tr>

                <tr>
                  <th>総売上</th>
                  <td v-for="information_record_data in information_record_datas" :key="information_record_data" v-bind:class="{ current_month : information_record_data.date === today_yyyy_mm_dd}">
                    <p v-if="information_record_data.totalSales">{{ Number(information_record_data.totalSales).toLocaleString() }}</p>
                    <p v-else>-</p>
                    </td>
                </tr>
                <tr>
                  <th>技術売上</th>
                  <td v-for="information_record_data in information_record_datas" :key="information_record_data" v-bind:class="{ current_month : information_record_data.date === today_yyyy_mm_dd}">
                    <p v-if="information_record_data.techSales">{{ Number(information_record_data.techSales).toLocaleString() }}</p>
                    <p v-else>-</p>
                  </td>
                </tr>
                <tr>
                  <th>商品売上</th>
                  <td v-for="information_record_data in information_record_datas" :key="information_record_data" v-bind:class="{ current_month : information_record_data.date === today_yyyy_mm_dd}">
                    <p v-if="information_record_data.itemSales">{{ Number(information_record_data.itemSales).toLocaleString() }}</p>
                    <p v-else>-</p>
                  </td>
                </tr>
                <tr>
                  <th>その他売上</th>
                  <td v-for="information_record_data in information_record_datas" :key="information_record_data" v-bind:class="{ current_month : information_record_data.date === today_yyyy_mm_dd}">
                    <p v-if="information_record_data.otherSales">{{ Number(information_record_data.otherSales).toLocaleString() }}</p>
                    <p v-else>-</p>
                  </td>
                </tr>
                <tr>
                  <th>総客数</th>
                  <td v-for="information_record_data in information_record_datas" :key="information_record_data" v-bind:class="{ current_month : information_record_data.date === today_yyyy_mm_dd}">
                    <p v-if="information_record_data.numberOfCustomers">{{ Number(information_record_data.numberOfCustomers).toLocaleString() }}</p>
                    <p v-else>-</p>
                    </td>
                </tr>
              </table>

            </dd>
          </dl>
        </div>

      </section>
    </transition>

    <transition name="fade">
      <div class="flash" v-if="send_alert">
        更新しました
      </div>
    </transition>

    <div id="login">
      <section class="notice display-width" v-show="sended"> 
        <h2><span></span>NOTICE<span></span></h2>
        <button class="btn_close" @click="all_Close()">×</button>
        <p>
          登録されたメールアドレスに<br>
          メールを送信いたしました。<br>
          <br> 
          送信されたメールから<br> 
          登録してください。<br>
          <br>
          ※お使いの環境によっては<br>
          「迷惑メール」等に<br>
          振り分けられている場合があります。<br>
          ご注意ください。<br>
          <br>
          ※10分経ってもメールが来ない<br>
          場合は、管理者に連絡してください。
        </p>
      </section>
    </div>
          
  </div>
</template>

<style>
    .flash {
      position: fixed;
      top: 50%;
      left: 50%;
      width: 200px;
      height: auto;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
      padding: 40px 15px;
      box-sizing: border-box;
      color:#fff;
      font-weight:bold;
      text-align: center;
      background:  var(--main_color);
      border-radius:20px;
      z-index: 100;
    }
    .fade-enter-active, .fade-leave-active
     {
        transition: opacity .3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .display-width {
      max-width: 370px;
    }
</style>

<script>
import MonthlyGraph from './monthly_graph'
import YearlyGraph from './yearly_graph'

export default {
  components: {
    MonthlyGraph,
    YearlyGraph
  },

  props: ['email'],

  data: function() {
    return {
      sended: false,
      monthly_bottom_btn: false,
      
      monthly_bottom_btn_input: false,
      monthly_bottom_btn_input_easy: false,

      monthly_bottom_btn_do: false,
      monthly_bottom_btn_do_easy: false,
      p_bg: false,
      p_bg_active: false,

      month_Rank: false,
      month_Data: false,
      yearly_Data: false,
      yearly_Rank: false,
      cheack_data: false,

      rank_alert: false,

      i_btn: false,
      i_btn_plan: false,
      i_btn_do: false,
      i_bg: false,

      graf_i_btn: false,
      graf_i_year_btn: false,

      glaf_tab_active: false,

      /** selectの中身 */
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      years: [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],

      /** 目標系 */
      input_day: {
        input_year:"",
        input_month:""
      },
      days_of_month: "",
      input_totalSales: "",
      input_techSales: "",
      input_itemSales: "",
      input_otherSales: "",
      input_numberOfCustomers: "",
      input_time: "",

      /** 実績系 */
      do_day: "",
      do_totalSales: "",
      do_techSales: "",
      do_itemSales: "",
      do_otherSales: "",
      do_numberOfCustomers: "",
      do_time: "",

      send_alert: false,
      today_yyyy_mm_dd: "",
      only_today_year_month: "",
      display_totalSale_0_flag: false,

      /** 例外処理のエラー文 */
      // 半角数字じゃないときにでる
      full_input_number_error_flag: 1,
      easy_input_number_error_flag: 1,
      full_do_number_error_flag: 1,
      easy_do_number_error_flag: 1,
      // 空の時に出る
      full_input_null_error_flag: 1,
      easy_input_null_error_flag: 1,
      full_do_null_error_flag: 1,
      easy_do_null_error_flag: 1,

      /** input及びdoのinformation画面用のデータ */
      information_goal_datas: [],
      information_record_datas: [],

      /** check画面用 */
      //グラフ画面
      yearly_records_data: {
        "totalSales": 0,
        "techSales": 0,
        "itemSales": 0,
        "otherSales": 0,
        "numberOfCustomers": 0,
        "time": 0,
        "workingDays": 0,
      },
      monthly_records_data: {
        "totalSales": 0,
        "techSales": 0,
        "itemSales": 0,
        "otherSales": 0,
        "numberOfCustomers": 0,
        "time": 0,
        "workingDays": 0,
      },
      //達成率用
      yearly_goals_data: {
        "totalSales": 0,
        "techSales": 0,
        "itemSales": 0,
        "otherSales": 0,
        "numberOfCustomers": 0,
        "time": 0,
      },
      monthly_goals_data: {
        "totalSales": 0,
        "techSales": 0,
        "itemSales": 0,
        "otherSales": 0,
        "numberOfCustomers": 0,
        "time": 0,
      },
      //information画面
      information_yearly_data: [],
      information_monthly_data: [],
    }
  },

  computed: {
    /** 目標系 */
    //目標総売上（表示のみ）
    input_display_totalSale: {
      get:function () {
        let month_data_techSales = this.input_techSales ? parseInt(this.input_techSales) : 0;
        let month_data_itemSales = this.input_itemSales ? parseInt(this.input_itemSales) : 0;
        let month_data_otherSales = this.input_otherSales ? parseInt(this.input_otherSales) : 0;
        if (!this.display_totalSale_0_flag) {
          if (month_data_techSales + month_data_itemSales + month_data_otherSales !== 0) {
            return month_data_techSales + month_data_itemSales + month_data_otherSales;
          } else {
            if (!this.input_totalSales) {
              return 0;
            } else {
            return this.input_totalSales;
            }
          }
        } else {
            return month_data_techSales + month_data_itemSales + month_data_otherSales;
        }
      }
    },
    /** 実績系 */
    //実績総売上（表示のみ）
    do_display_totalSale: {
      get:function () {
        let daily_data_techSales = this.do_techSales ? parseInt(this.do_techSales) : 0;
        let daily_data_itemSales = this.do_itemSales ? parseInt(this.do_itemSales) : 0;
        let daily_data_otherSales = this.do_otherSales ? parseInt(this.do_otherSales) : 0;
        if (!this.display_totalSale_0_flag) {
          if (daily_data_techSales + daily_data_itemSales + daily_data_otherSales !== 0) {
            return daily_data_techSales + daily_data_itemSales + daily_data_otherSales;
          } else {
            if (!this.do_totalSales) {
              return 0;
            } else {
            return this.do_totalSales;
            }
          }
        } else {
            return daily_data_techSales + daily_data_itemSales + daily_data_otherSales;
        }
      },
    },
    /**
     * check画面用
     */
    /** ----------------------------- 月 -------------------------------------------- */
    /**
     * 累計(単価関係)
     */
    // 総客単価
    monthly_total_per_customer: {
      get:function () { return this.unit_round_method(this.monthly_records_data.totalSales, this.monthly_records_data.numberOfCustomers); },
    },
    // 技術客単価
    monthly_tech_per_customer: {
      get:function () { return this.unit_round_method(this.monthly_records_data.techSales, this.monthly_records_data.numberOfCustomers); },
    },
    // 商品客単価
    monthly_item_per_customer: {
      get:function () { return this.unit_round_method(this.monthly_records_data.itemSales, this.monthly_records_data.numberOfCustomers); },
    },
    // その他客単価
    monthly_other_per_customer: {
      get:function () { return this.unit_round_method(this.monthly_records_data.otherSales, this.monthly_records_data.numberOfCustomers); },
    },
    // 総売上時間単価
    monthly_total_per_time: {
      get:function () { return this.unit_round_method(this.monthly_records_data.totalSales, this.monthly_records_data.time); },
    },
    // 技術売上時間単価
    monthly_tech_per_time: {
      get:function () { return this.unit_round_method(this.monthly_records_data.techSales, this.monthly_records_data.time); },
    },
    // 商品売上時間単価
    monthly_item_per_time: {
      get:function () { return this.unit_round_method(this.monthly_records_data.itemSales, this.monthly_records_data.time); },
    },
    // その他売上時間単価
    monthly_other_per_time: {
      get:function () { return this.unit_round_method(this.monthly_records_data.otherSales, this.monthly_records_data.time); },
    },

    /**
     * 日平均
     */
    // 日は仮データ(30)
    //総売上日平均
    monthly_total_per_day: {
      get:function () { return this.unit_round_method(this.monthly_records_data.totalSales, this.monthly_records_data.workingDays); },
    },
    //技術売上日平均
    monthly_tech_per_day: {
      get:function () { return this.unit_round_method(this.monthly_records_data.techSales, this.monthly_records_data.workingDays); },
    },
    //商品売上日平均
    monthly_item_per_day: {
      get:function () { return this.unit_round_method(this.monthly_records_data.itemSales, this.monthly_records_data.workingDays); },
    },
    //その他売上日平均
    monthly_other_per_day: {
      get:function () { return this.unit_round_method(this.monthly_records_data.otherSales, this.monthly_records_data.workingDays); },
    },
    //客数日平均
    monthly_customer_per_day: {
      get:function () { return this.unit_round_method(this.monthly_records_data.numberOfCustomers, this.monthly_records_data.workingDays); },
    },
    //総客単価日平均
    monthly__total_per_customer__per_day: {
      get:function () { return this.unit_round_method(this.monthly_total_per_customer, this.monthly_records_data.workingDays); },
    },
    //技術客単価日平均
    monthly__tech_per_customer__per_day: {
      get:function () { return this.unit_round_method(this.monthly_tech_per_customer, this.monthly_records_data.workingDays); },
    },
    //商品客単価日平均
    monthly__item_per_customer__per_day: {
      get:function () {
        return this.unit_round_method(this.monthly_item_per_customer, this.monthly_records_data.workingDays);
      },
    },
    //その他客単価日平均
    monthly__other_per_customer__per_day: {
      get:function () { return this.unit_round_method(this.monthly_other_per_customer, this.monthly_records_data.workingDays); },
    },
    //仕事時間日平均
    monthly_time_per_day: {
      get:function () { return this.unit_round_method(this.monthly_records_data.time, this.monthly_records_data.workingDays); },
    },
    //総時間単価日平均
    monthly__total_per_time__per_day: {
      get:function () { return this.unit_round_method(this.monthly_total_per_time, this.monthly_records_data.workingDays); },
    },
    //技術時間単価日平均
    monthly__tech_per_time__per_day: {
      get:function () { return this.unit_round_method(this.monthly_tech_per_time, this.monthly_records_data.workingDays); },
    },
    //商品時間単価日平均
    monthly__item_per_time__per_day: {
      get:function () { return this.unit_round_method(this.monthly_item_per_time, this.monthly_records_data.workingDays); },
    },
    //その他時間単価日平均
    monthly__other_per_time__per_day: {
      get:function () { return this.unit_round_method(this.monthly_other_per_time, this.monthly_records_data.workingDays); },
    },
    /**
     * 達成率
     */
    //総売上達成率
    monthly_total_record_per_goal: {
      get:function () { return this.rate_round_method(this.monthly_records_data.totalSales, this.monthly_goals_data.totalSales); }
    },
    //技術売上達成率
    monthly_tech_record_per_goal: {
      get:function () { return this.rate_round_method(this.monthly_records_data.techSales, this.monthly_goals_data.techSales); }
    },
    //商品売上達成率
    monthly_item_record_per_goal: {
      get:function () { return this.rate_round_method(this.monthly_records_data.itemSales, this.monthly_goals_data.itemSales); }
    },
    //その他売上達成率
    monthly_other_record_per_goal: {
      get:function () { return this.rate_round_method(this.monthly_records_data.otherSales, this.monthly_goals_data.otherSales); }
    },
    //客数達成率
    monthly_customer_record_per_goal: {
      get:function () { return this.rate_round_method(this.monthly_records_data.numberOfCustomers, this.monthly_goals_data.numberOfCustomers); }
    },
    //総客単価達成率
    //下で使う変数を定義
    monthly_goals_total_per_customer: {
      get:function () { return this.unit_round_method(this.monthly_goals_data.totalSales, this.monthly_goals_data.numberOfCustomers); },
    },
    monthly__total_per_customer__record_per_goal: {
      get:function () { return this.rate_round_method(this.monthly_total_per_customer, this.monthly_goals_total_per_customer); }
    },
    //技術客単価達成率
    //下で使う変数を定義
    monthly_goals_tech_per_customer: {
      get:function () { return this.unit_round_method(this.monthly_goals_data.techSales, this.monthly_goals_data.numberOfCustomers); },
    },
    monthly__tech_per_customer__record_per_goal: {
      get:function () { return this.rate_round_method(this.monthly_tech_per_customer, this.monthly_goals_tech_per_customer); }
    },
    //商品客単価達成率
    //下で使う変数を定義
    monthly_goals_item_per_customer: {
      get:function () { return this.unit_round_method(this.monthly_goals_data.itemSales, this.monthly_goals_data.numberOfCustomers); },
    },
    monthly__item_per_customer__record_per_goal: {
      get:function () { return this.rate_round_method(this.monthly_item_per_customer, this.monthly_goals_item_per_customer); }
    },
    //その他客単価達成率
    //下で使う変数を定義
    monthly_goals_other_per_customer: {
      get:function () { return this.unit_round_method(this.monthly_goals_data.otherSales, this.monthly_goals_data.numberOfCustomers); },
    },
    monthly__other_per_customer__record_per_goal: {
      get:function () { return this.rate_round_method(this.monthly_other_per_customer, this.monthly_goals_other_per_customer); }
    },
    //仕事時間達成率
    //先方の要望により、仕事時間が短いほうが良い＝達成率が高い方がいい方がいいので分母と分子を反対にする
    monthly_time_record_per_goal: {
      get:function () { return this.rate_round_method(this.monthly_goals_data.time, this.monthly_records_data.time); }
    },
    //総時間単価達成率
    //下で使う変数を定義
    monthly_goals_total_per_time: {
      get:function () { return this.unit_round_method(this.monthly_goals_data.totalSales, this.monthly_goals_data.time); },
    },
    monthly__total_per_time__record_per_goal: {
      get:function () { return this.rate_round_method(this.monthly_total_per_time, this.monthly_goals_total_per_time); }
    },
    //技術時間単価達成率
    //下で使う変数を定義
    monthly_goals_tech_per_time: {
      get:function () { return this.unit_round_method(this.monthly_goals_data.techSales, this.monthly_goals_data.time); },
    },
    monthly__tech_per_time__record_per_goal: {
      get:function () { return this.rate_round_method(this.monthly_tech_per_time, this.monthly_goals_tech_per_time); }
    },
    //商品時間単価達成率
    //下で使う変数を定義
    monthly_goals_item_per_time: {
      get:function () { return this.unit_round_method(this.monthly_goals_data.itemSales, this.monthly_goals_data.time); },
    },
    monthly__item_per_time__record_per_goal: {
      get:function () { return this.rate_round_method(this.monthly_item_per_time, this.monthly_goals_item_per_time); }
    },
    //その他時間単価達成率
    //下で使う変数を定義
    monthly_goals_other_per_time: {
      get:function () { return this.unit_round_method(this.monthly_goals_data.otherSales, this.monthly_goals_data.time); },
    },
    monthly__other_per_time__record_per_goal: {
      get:function () { return this.rate_round_method(this.monthly_other_per_time, this.monthly_goals_other_per_time); }
    },

    /** ----------------------------- 年 -------------------------------------------- */
    /**
     * 累計(単価関係)
     */
    // 総客単価
    yearly_total_per_customer: {
      get:function () { return this.unit_round_method(this.yearly_records_data.totalSales, this.yearly_records_data.numberOfCustomers); },
    },
    // 技術客単価
    yearly_tech_per_customer: {
      get:function () { return this.unit_round_method(this.yearly_records_data.techSales, this.yearly_records_data.numberOfCustomers); },
    },
    // 商品客単価
    yearly_item_per_customer: {
      get:function () { return this.unit_round_method(this.yearly_records_data.itemSales, this.yearly_records_data.numberOfCustomers); },
    },
    // その他客単価
    yearly_other_per_customer: {
      get:function () { return this.unit_round_method(this.yearly_records_data.otherSales, this.yearly_records_data.numberOfCustomers); },
    },
    // 総売上時間単価
    yearly_total_per_time: {
      get:function () { return this.unit_round_method(this.yearly_records_data.totalSales, this.yearly_records_data.time); },
    },
    // 技術売上時間単価
    yearly_tech_per_time: {
      get:function () { return this.unit_round_method(this.yearly_records_data.techSales, this.yearly_records_data.time); },
    },
    // 商品売上時間単価
    yearly_item_per_time: {
      get:function () { return this.unit_round_method(this.yearly_records_data.itemSales, this.yearly_records_data.time); },
    },
    // その他売上時間単価
    yearly_other_per_time: {
      get:function () { return this.unit_round_method(this.yearly_records_data.otherSales, this.yearly_records_data.time); },
    },

    /**
     * 日平均
     */
    // 日は仮データ(30)
    //総売上日平均
    yearly_total_per_day: {
      get:function () { return this.unit_round_method(this.yearly_records_data.totalSales, this.yearly_records_data.workingDays); },
    },
    //技術売上日平均
    yearly_tech_per_day: {
      get:function () { return this.unit_round_method(this.yearly_records_data.techSales, this.yearly_records_data.workingDays); },
    },
    //商品売上日平均
    yearly_item_per_day: {
      get:function () { return this.unit_round_method(this.yearly_records_data.itemSales, this.yearly_records_data.workingDays); },
    },
    //その他売上日平均
    yearly_other_per_day: {
      get:function () { return this.unit_round_method(this.yearly_records_data.otherSales, this.yearly_records_data.workingDays); },
    },
    //客数日平均
    yearly_customer_per_day: {
      get:function () { return this.unit_round_method(this.yearly_records_data.numberOfCustomers, this.yearly_records_data.workingDays); },
    },
    //総客単価日平均
    yearly__total_per_customer__per_day: {
      get:function () { return this.unit_round_method(this.yearly_total_per_customer, this.yearly_records_data.workingDays); },
    },
    //技術客単価日平均
    yearly__tech_per_customer__per_day: {
      get:function () { return this.unit_round_method(this.yearly_tech_per_customer, this.yearly_records_data.workingDays); },
    },
    //商品客単価日平均
    yearly__item_per_customer__per_day: {
      get:function () {
        return this.unit_round_method(this.yearly_item_per_customer, this.yearly_records_data.workingDays);
      },
    },
    //その他客単価日平均
    yearly__other_per_customer__per_day: {
      get:function () { return this.unit_round_method(this.yearly_other_per_customer, this.yearly_records_data.workingDays); },
    },
    //仕事時間日平均
    yearly_time_per_day: {
      get:function () { return this.unit_round_method(this.yearly_records_data.time, this.yearly_records_data.workingDays); },
    },
    //総時間単価日平均
    yearly__total_per_time__per_day: {
      get:function () { return this.unit_round_method(this.yearly_total_per_time, this.yearly_records_data.workingDays); },
    },
    //技術時間単価日平均
    yearly__tech_per_time__per_day: {
      get:function () { return this.unit_round_method(this.yearly_tech_per_time, this.yearly_records_data.workingDays); },
    },
    //商品時間単価日平均
    yearly__item_per_time__per_day: {
      get:function () { return this.unit_round_method(this.yearly_item_per_time, this.yearly_records_data.workingDays); },
    },
    //その他時間単価日平均
    yearly__other_per_time__per_day: {
      get:function () { return this.unit_round_method(this.yearly_other_per_time, this.yearly_records_data.workingDays); },
    },
    /**
     * 達成率
     */
    //総売上達成率
    yearly_total_record_per_goal: {
      get:function () { return this.rate_round_method(this.yearly_records_data.totalSales, this.yearly_goals_data.totalSales); }
    },
    //技術売上達成率
    yearly_tech_record_per_goal: {
      get:function () { return this.rate_round_method(this.yearly_records_data.techSales, this.yearly_goals_data.techSales); }
    },
    //商品売上達成率
    yearly_item_record_per_goal: {
      get:function () { return this.rate_round_method(this.yearly_records_data.itemSales, this.yearly_goals_data.itemSales); }
    },
    //その他売上達成率
    yearly_other_record_per_goal: {
      get:function () { return this.rate_round_method(this.yearly_records_data.otherSales, this.yearly_goals_data.otherSales); }
    },
    //客数達成率
    yearly_customer_record_per_goal: {
      get:function () { return this.rate_round_method(this.yearly_records_data.numberOfCustomers, this.yearly_goals_data.numberOfCustomers); }
    },
    //総客単価達成率
    //下で使う変数を定義
    yearly_goals_total_per_customer: {
      get:function () { return this.unit_round_method(this.yearly_goals_data.totalSales, this.yearly_goals_data.numberOfCustomers); },
    },
    yearly__total_per_customer__record_per_goal: {
      get:function () { return this.rate_round_method(this.yearly_total_per_customer, this.yearly_goals_total_per_customer); }
    },
    //技術客単価達成率
    //下で使う変数を定義
    yearly_goals_tech_per_customer: {
      get:function () { return this.unit_round_method(this.yearly_goals_data.techSales, this.yearly_goals_data.numberOfCustomers); },
    },
    yearly__tech_per_customer__record_per_goal: {
      get:function () { return this.rate_round_method(this.yearly_tech_per_customer, this.yearly_goals_tech_per_customer); }
    },
    //商品客単価達成率
    //下で使う変数を定義
    yearly_goals_item_per_customer: {
      get:function () { return this.unit_round_method(this.yearly_goals_data.itemSales, this.yearly_goals_data.numberOfCustomers); },
    },
    yearly__item_per_customer__record_per_goal: {
      get:function () { return this.rate_round_method(this.yearly_item_per_customer, this.yearly_goals_item_per_customer); }
    },
    //その他客単価達成率
    //下で使う変数を定義
    yearly_goals_other_per_customer: {
      get:function () { return this.unit_round_method(this.yearly_goals_data.otherSales, this.yearly_goals_data.numberOfCustomers); },
    },
    yearly__other_per_customer__record_per_goal: {
      get:function () { return this.rate_round_method(this.yearly_other_per_customer, this.yearly_goals_other_per_customer); }
    },
    //仕事時間達成率
    //先方の要望により、仕事時間が短いほうが良い＝達成率が高い方がいい方がいいので分母と分子を反対にする
    yearly_time_record_per_goal: {
      get:function () { return this.rate_round_method(this.yearly_goals_data.time, this.yearly_records_data.time); }
    },
    //総時間単価達成率
    //下で使う変数を定義
    yearly_goals_total_per_time: {
      get:function () { return this.unit_round_method(this.yearly_goals_data.totalSales, this.yearly_goals_data.time); },
    },
    yearly__total_per_time__record_per_goal: {
      get:function () { return this.rate_round_method(this.yearly_total_per_time, this.yearly_goals_total_per_time); }
    },
    //技術時間単価達成率
    //下で使う変数を定義
    yearly_goals_tech_per_time: {
      get:function () { return this.unit_round_method(this.yearly_goals_data.techSales, this.yearly_goals_data.time); },
    },
    yearly__tech_per_time__record_per_goal: {
      get:function () { return this.rate_round_method(this.yearly_tech_per_time, this.yearly_goals_tech_per_time); }
    },
    //商品時間単価達成率
    //下で使う変数を定義
    yearly_goals_item_per_time: {
      get:function () { return this.unit_round_method(this.yearly_goals_data.itemSales, this.yearly_goals_data.time); },
    },
    yearly__item_per_time__record_per_goal: {
      get:function () { return this.rate_round_method(this.yearly_item_per_time, this.yearly_goals_item_per_time); }
    },
    //その他時間単価達成率
    //下で使う変数を定義
    yearly_goals_other_per_time: {
      get:function () { return this.unit_round_method(this.yearly_goals_data.otherSales, this.yearly_goals_data.time); },
    },
    yearly__other_per_time__record_per_goal: {
      get:function () { return this.rate_round_method(this.yearly_other_per_time, this.yearly_goals_other_per_time); }
    },

  },

  watch: {
    input_totalSales :{
      handler : function(){
        this.full_input_validation_check();
        this.easy_input_validation_check();
      },
      deep:true
    },
    input_techSales :{
      handler : function(){
        this.full_input_validation_check();
        this.easy_input_validation_check();
      },
      deep:true
    },
    input_itemSales :{
      handler : function(){
        this.full_input_validation_check();
        this.easy_input_validation_check();
      },
      deep:true
    },
    input_otherSales :{
      handler : function(){
        this.full_input_validation_check();
        this.easy_input_validation_check();
      },
      deep:true
    },
    input_numberOfCustomers :{
      handler : function(){
        this.full_input_validation_check();
        this.easy_input_validation_check();
      },
      deep:true
    },
    input_time :{
      handler : function(){
        this.full_input_validation_check();
        this.easy_input_validation_check();
      },
      deep:true
    },
    do_totalSales :{
      handler : function(){
        this.full_do_validation_check();
        this.easy_do_validation_check();
      },
      deep:true
    },
    do_techSales :{
      handler : function(){
        this.full_do_validation_check();
        this.easy_do_validation_check();
      },
      deep:true
    },
    do_itemSales :{
      handler : function(){
        this.full_do_validation_check();
        this.easy_do_validation_check();
      },
      deep:true
    },
    do_otherSales :{
      handler : function(){
        this.full_do_validation_check();
        this.easy_do_validation_check();
      },
      deep:true
    },
    do_numberOfCustomers :{
      handler : function(){
        this.full_do_validation_check();
        this.easy_do_validation_check();
      },
      deep:true
    },
    do_time :{
      handler : function(){
        this.full_do_validation_check();
        this.easy_do_validation_check();
      },
      deep:true
    },
    do_display_totalSale :{
      handler : function(){
        this.do_totalSales = this.do_display_totalSale;
      },
      deep:true
    },
    input_display_totalSale :{
      handler : function(){
        this.input_totalSales = this.input_display_totalSale;
      },
      deep:true
    },
    do_day :{
      handler : function(){
        let self = this;
        /** 日付系の処理 */
        //日付をdateオブジェクト化:object_day
        let array_day = this.do_day.split("-");
        let object_day = new Date(array_day[0],array_day[1] - 1,array_day[2]);

        let today = new Date();
        this.today_yyyy_mm_dd = today.getFullYear() + "-" + ("00" + (today.getMonth()+1)).slice(-2) + "-" + ("00" + today.getDate()).slice(-2);
        /** 目標系 */
        //選択された月:this.input_day.input_month
        if (this.input_day.input_month == "") {
          this.input_day.input_month = object_day.getMonth() + 1;
        }
        //選択された年:this.input_day.input_year
        if (this.input_day.input_year == "") {
          this.input_day.input_year = object_day.getFullYear();
        }
        this.input_day.input_day = object_day.getDate();

        let year_month = this.do_day.split("-");
        let get_only_year = String(year_month[0]);
        let get_only_month = String(year_month[1]).length === 1 ? "0" + String(year_month[1]) : String(year_month[1]);
        let get_only_day = String(year_month[2]).length === 1 ? "0" + String(year_month[2]) : String(year_month[2]);
        this.axios.get(`goals/monthly/${get_only_year}/${get_only_month}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          if (response.data !== "") {
            self.input_totalSales = response.data.totalSales;
            self.input_techSales = response.data.techSales;
            self.input_itemSales = response.data.itemSales;
            self.input_otherSales = response.data.otherSales;
            self.input_numberOfCustomers = response.data.numberOfCustomers;
            self.input_time = response.data.time;
          } else {
            self.input_totalSales = "";
            self.input_techSales = "";
            self.input_itemSales = "";
            self.input_otherSales = "";
            self.input_numberOfCustomers = "";
            self.input_time = "";
          }
        })
        .catch((e) => {
          console.log(e);
        });

        // 実績取得
        this.axios.get(`records/daily/${get_only_year}/${get_only_month}/${get_only_day}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          if (response.data !== "") {
            self.do_totalSales = response.data.totalSales;
            self.do_techSales = response.data.techSales;
            self.do_itemSales = response.data.itemSales;
            self.do_otherSales = response.data.otherSales;
            self.do_numberOfCustomers = response.data.numberOfCustomers;
            self.do_time = response.data.time;
          } else {
            self.do_totalSales = "";
            self.do_techSales = "";
            self.do_itemSales = "";
            self.do_otherSales = "";
            self.do_numberOfCustomers = "";
            self.do_time = "";
          }
        })
        .catch((e) => {
          console.log(e);
        });
      },
      deep:true
    },
    input_day :{
      handler : function(){
        let self = this;
        let today = new Date();
        this.only_today_year_month = today.getFullYear() + "-" + ("00" + (today.getMonth()+1)).slice(-2);
        let next_month = new Date(this.input_day.input_year, this.input_day.input_month , 0);
        this.days_of_month = next_month.getDate();
        /** 目標系 */
        let mm_month = String(this.input_day.input_month).length === 1 ? "0" + String(this.input_day.input_month) : String(this.input_day.input_month);
        let year_month = String(this.input_day.input_year) + "-" + mm_month;
        this.axios.get(`goals/monthly/${String(this.input_day.input_year)}/${mm_month}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          if (response.data !== "") {
            self.input_totalSales = response.data.totalSales;
            self.input_techSales = response.data.techSales;
            self.input_itemSales = response.data.itemSales;
            self.input_otherSales = response.data.otherSales;
            self.input_numberOfCustomers = response.data.numberOfCustomers;
            self.input_time = response.data.time;
          } else {
            self.input_totalSales = "";
            self.input_techSales = "";
            self.input_itemSales = "";
            self.input_otherSales = "";
            self.input_numberOfCustomers = "";
            self.input_time = "";
          }
        })
        .catch((e) => {
          console.log(e);
        });

        // 実績取得
        if (this.do_day === "") {
          this.do_day = today.getFullYear() + "-" + ("00" + (today.getMonth()+1)).slice(-2) + "-" + ("00" + today.getDate()).slice(-2);
          this.axios.get(`records/daily/${today.getFullYear()}/${("00" + (today.getMonth()+1)).slice(-2)}/${("00" + today.getDate()).slice(-2)}`, {
            headers: {
              Authorization: this.$localStorage.get('accessToken')
            }
          })
          .then((response) => {
            if (response.data !== "") {
              self.do_totalSales = response.data.totalSales;
              self.do_techSales = response.data.techSales;
              self.do_itemSales = response.data.itemSales;
              self.do_otherSales = response.data.otherSales;
              self.do_numberOfCustomers = response.data.numberOfCustomers;
              self.do_time = response.data.time;
            } else {
              self.do_totalSales = "";
              self.do_techSales = "";
              self.do_itemSales = "";
              self.do_otherSales = "";
              self.do_numberOfCustomers = "";
              self.do_time = "";
            }
          })
          .catch((e) => {
            console.log(e);
          });
        }

        /**
         * check画面用
         */
        /** 実績 */
        /** yearly */
        // 初期化
        this.yearly_records_data = {
          "totalSales":0,
          "techSales":0,
          "itemSales":0,
          "otherSales":0,
          "numberOfCustomers":0,
          "time":0,
          "workingDays":0,
        }
        this.axios.get(`records/yearly-total/${self.input_day.input_year}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          // 年のinformation画面用
          self.information_yearly_data = response.data;

          for (let i = 0; i < response.data.length; i++) {
            self.yearly_records_data.totalSales += Number(response.data[i].totalSales);
            self.yearly_records_data.techSales += Number(response.data[i].techSales);
            self.yearly_records_data.itemSales += Number(response.data[i].itemSales);
            self.yearly_records_data.otherSales += Number(response.data[i].otherSales);
            self.yearly_records_data.numberOfCustomers += Number(response.data[i].numberOfCustomers);
            self.yearly_records_data.time += Number(response.data[i].time);
            self.yearly_records_data.workingDays += Number(response.data[i].workingDays);
          }
          /** montyly（別のapi呼び出すよりここでフィルタかけた方が処理早そうなのでここで取得する） */
          response.data.filter(function(item) {
            if (item.month === year_month) {
              self.monthly_records_data = item;
            }
          })
        })
        .catch((e) => {
          console.log(e);
        });

        /** 目標 */
        /** yearly */
        // 初期化
        this.yearly_goals_data = {
          "totalSales":0,
          "techSales":0,
          "itemSales":0,
          "otherSales":0,
          "numberOfCustomers":0,
          "time":0,
        }

        this.axios.get(`goals/yearly/${self.input_day.input_year}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            self.yearly_goals_data.totalSales += Number(response.data[i].totalSales);
            self.yearly_goals_data.techSales += Number(response.data[i].techSales);
            self.yearly_goals_data.itemSales += Number(response.data[i].itemSales);
            self.yearly_goals_data.otherSales += Number(response.data[i].otherSales);
            self.yearly_goals_data.numberOfCustomers += Number(response.data[i].numberOfCustomers);
            self.yearly_goals_data.time += Number(response.data[i].time);
          }
        })
        .catch((e) => {
          console.log(e);
        });

        /** monthly */
        this.axios.get(`goals/monthly/${String(this.input_day.input_year)}/${mm_month}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          self.monthly_goals_data.totalSales = response.data.totalSales;
          self.monthly_goals_data.techSales = response.data.techSales;
          self.monthly_goals_data.itemSales = response.data.itemSales;
          self.monthly_goals_data.otherSales = response.data.otherSales;
          self.monthly_goals_data.numberOfCustomers = response.data.numberOfCustomers;
          self.monthly_goals_data.time = response.data.time;
        })
        .catch((e) => {
          console.log(e);
        });

        // 月のinformation画面用
        this.axios.get(`records/monthly/${String(this.input_day.input_year)}/${mm_month}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          if (response.data !== "") {
            self.information_monthly_data = response.data;
            for (let i = 0; i < self.information_monthly_data.length; i++) {
              let array_year_month = year_month.split("-");
              let object_day = new Date(array_year_month[0],array_year_month[1] - 1, i + 1);
              let only_day = object_day.getDate();
              self.information_monthly_data[i].only_day = only_day;
            }
            self.information_monthly_data = self.information_monthly_data.slice(0,self.days_of_month);
          } else {
            self.information_monthly_data = [{
              "totalSales": "",
              "techSales": "",
              "itemSales": "",
              "otherSales": "",
              "numberOfCustomers": "",
              "time": "",
              "date": ""
            }];
          }
        })
        .catch((e) => {
          console.log(e);
        });
        
        // グラフ用
        console.log(this.input_day.input_year);
        this.get_yearly_graph(this.input_day.input_year)
        this.get_monthly_graph(this.input_day.input_year, this.input_day.input_month)
      },
      deep:true
    },
  },
  
  methods: {
    //do_display_totalSale 又は input_display_totalSale が0を示すことを許可する処理（各売上をタップした時に着火）
    change_input_without_total:function() {
      this.display_totalSale_0_flag = true;
    },
    //単価計算共通項
    unit_round_method:function (a, b) {
      return a && b && b != 0 && isNaN(a) === false && isNaN(b) === false ? (Math.round( a / b * 10) /10).toLocaleString() : "-";
    },
    //達成率計算共通項
    rate_round_method:function (a, b) {
      return a && b && b != 0 && isNaN(a) === false && isNaN(b) === false ? (Math.round( a / b * 100)).toLocaleString() : "-";
    },
    // full inputの例外処理
    full_input_validation_check: function () {
      // 空チェック
      if (this.input_techSales === "" || this.input_itemSales === "" || this.input_otherSales === "" || this.input_numberOfCustomers === "" || this.input_time === "" ) {
        this.full_input_null_error_flag = 1;
      } else {
        this.full_input_null_error_flag = 0;
      }
      // 半角数字チェック
      if (String(this.input_techSales).match(/[^0-9]+/) || String(this.input_itemSales).match(/[^0-9]+/) || String(this.input_otherSales).match(/[^0-9]+/) || String(this.input_numberOfCustomers).match(/[^0-9]+/) || String(this.input_time).match(/[^0-9]+/) ) {
        this.full_input_number_error_flag = 1;
      } else {
        this.full_input_number_error_flag = 0;
      }
    },
    // easy input の例外処理
    easy_input_validation_check: function () {
      // 空チェック
      if (this.input_totalSales === "") {
        this.easy_input_null_error_flag = 1;
      } else {
        this.easy_input_null_error_flag = 0;
      }
      // 半角数字チェック
      if (String(this.input_totalSales).match(/[^0-9]+/)) {
        this.easy_input_number_error_flag = 1;
      } else {
        this.easy_input_number_error_flag = 0;
      }
    },
    // full doの例外処理
    full_do_validation_check: function () {
      // 空チェック
      if (this.do_techSales === "" || this.do_itemSales === "" || this.do_otherSales === "" || this.do_numberOfCustomers === "" || this.do_time === "" ) {
        this.full_do_null_error_flag = 1;
      } else {
        this.full_do_null_error_flag = 0;
      }
      // 半角数字チェック
      if (String(this.do_techSales).match(/[^0-9]+/) || String(this.do_itemSales).match(/[^0-9]+/) || String(this.do_otherSales).match(/[^0-9]+/) || String(this.do_numberOfCustomers).match(/[^0-9]+/) || String(this.do_time).match(/[^0-9]+/) ) {
        this.full_do_number_error_flag = 1;
      } else {
        this.full_do_number_error_flag = 0;
      }
    },
    // easy input の例外処理
    easy_do_validation_check: function () {
      // 空チェック
      if (this.do_totalSales === "") {
        this.easy_do_null_error_flag = 1;
      } else {
        this.easy_do_null_error_flag = 0;
      }
      // 半角数字チェック
      if (String(this.do_totalSales).match(/[^0-9]+/)) {
        this.easy_do_number_error_flag = 1;
      } else {
        this.easy_do_number_error_flag = 0;
      }
    },
    // Full Do 決定ボタンクリック
    set_full_do: function () {
      let self = this;

      const params = new URLSearchParams();
      params.append('date', this.do_day);
      params.append('totalSales', this.do_totalSales);
      params.append('techSales', this.do_techSales);
      params.append('itemSales', this.do_itemSales);
      params.append('otherSales', this.do_otherSales);
      params.append('numberOfCustomers', this.do_numberOfCustomers);
      params.append('time', this.do_time);

      this.axios.post('records/daily', params, {
        headers:{
          Authorization: this.$localStorage.get('accessToken')
        }
      })
      .then((response) => {
        self.$emit("do_new_data",response.data);
        self.$emit("new_date",self.do_day);
        //alert("登録完了しました");
        this.send_alert = true;
        // setTimeoutで1500ms後にshowをfalseにする
        setTimeout(() => {this.send_alert = false},1500);
        self.monthly_bottom_btnClose();
        // this.$router.go({path: this.$router.currentRoute.path, force: true})
      })
      .catch((e) => {
        console.log(e);
      });
    },

    // Easy Do 決定ボタンクリック
    set_easy_do: function () {
      let self = this;

      const params = new URLSearchParams();
      params.append('date', this.do_day);
      params.append('totalSales', this.do_totalSales);

      this.axios.post('records/daily', params, {
        headers:{
          Authorization: this.$localStorage.get('accessToken')
        }
      })
      .then((response) => {
        self.$emit("do_new_data",response.data);
        self.$emit("new_date",self.do_day);
        //alert("登録完了しました");
        this.send_alert = true;
        // setTimeoutで3000ms後にshowをfalseにする
        setTimeout(() => {this.send_alert = false},3000);

        self.monthly_bottom_btnClose();
        // this.$router.go({path: this.$router.currentRoute.path, force: true})
      })
      .catch((e) => {
        console.log(e);
      });
    },
    // Full input 決定ボタンクリック
    set_full_input: function () {
      let self = this;
      let mm_month = String(this.input_day.input_month).length === 1 ? "0" + String(this.input_day.input_month) : String(this.input_day.input_month);
      let year_month = String(this.input_day.input_year) + "-" + mm_month;
      const params = new URLSearchParams();
      params.append('year', String(this.input_day.input_year));
      params.append('month', mm_month);
      params.append('totalSales', parseInt(this.input_totalSales));
      params.append('techSales', parseInt(this.input_techSales));
      params.append('itemSales', parseInt(this.input_itemSales));
      params.append('otherSales', parseInt(this.input_otherSales));
      params.append('numberOfCustomers', parseInt(this.input_numberOfCustomers));
      params.append('time', parseInt(this.input_time));
      this.axios.post('goals/monthly' , params , {
        headers: {
          Authorization: this.$localStorage.get('accessToken')
        }
      })
      .then((response) => {
        self.$emit("input-new-data",response.data);
        self.$emit("new_date",year_month);
        //alert("登録完了しました");
        this.send_alert = true;
        // setTimeoutで1500ms後にshowをfalseにする
        setTimeout(() => {this.send_alert = false},1500);
        self.monthly_bottom_btnClose();
      })
      .catch((e) => {
        console.log(e);
      });
    },
    // Easy input 決定ボタンクリック
    set_easy_input: function () {
      let self = this;
      let mm_month = String(this.input_day.input_month).length === 1 ? "0" + String(this.input_day.input_month) : String(this.input_day.input_month);
      let year_month = String(this.input_day.input_year) + "-" + mm_month;
      const params = new URLSearchParams();
      params.append('year', String(this.input_day.input_year));
      params.append('month', mm_month);
      params.append('totalSales', parseInt(this.input_totalSales));
      params.append('techSales', 0);
      params.append('itemSales', 0);
      params.append('otherSales', 0);
      params.append('numberOfCustomers', parseInt(this.input_numberOfCustomers));
      params.append('time', parseInt(this.input_time));
      this.axios.post('goals/monthly' , params , {
        headers: {
          Authorization: this.$localStorage.get('accessToken')
        }
      })
      .then((response) => {
        self.$emit("input-new-data", response.data);
        self.$emit("new_date", year_month);
        //alert("登録完了しました");
        this.send_alert = true;
        // setTimeoutで3000ms後にshowをfalseにする
        setTimeout(() => {this.send_alert = false}, 1000);
        self.monthly_bottom_btnClose();
      })
      .catch((e) => {
        console.log(e);
      });
    },
    monthly_bottom_btnOpen: function(year, month) {
      this.input_day.input_year = year;
      this.input_day.input_month = month;
      let self = this;
      this.do_day = year + "-" + ("00" + String(month)).slice(-2) + "-01";
      this.display_totalSale_0_flag = false;
      /** 目標系 */
      let mm_month = String(month).length === 1 ? "0" + String(month) : String(month);
      this.axios.get(`goals/monthly/${String(year)}/${mm_month}`, {
        headers: {
          Authorization: this.$localStorage.get('accessToken')
        }
      })
      .then((response) => {
        if (response.data !== "") {
          self.input_totalSales = response.data.totalSales;
          self.input_techSales = response.data.techSales;
          self.input_itemSales = response.data.itemSales;
          self.input_otherSales = response.data.otherSales;
          self.input_numberOfCustomers = response.data.numberOfCustomers;
          self.input_time = response.data.time;
        } else {
          self.input_totalSales = "";
          self.input_techSales = "";
          self.input_itemSales = "";
          self.input_otherSales = "";
          self.input_numberOfCustomers = "";
          self.input_time = "";
        }
      })
      .catch((e) => {
        console.log(e);
      });

      this.monthly_bottom_btn = true;
      this.monthly_bottom_btn_input = true;
      this.monthly_bottom_btn_input_easy = false;
      this.monthly_bottom_btn_do_easy = false;
      this.p_bg = true;
      this.p_bg_active = false;
      this.monthly_bottom_btn_do = false;
    },
    monthly_bottom_btnOpen_easy: function() {
      this.monthly_bottom_btn = true;
      this.monthly_bottom_btn_input = false;
      this.monthly_bottom_btn_input_easy = true;
      this.monthly_bottom_btn_do = false;
      this.monthly_bottom_btn_do_easy = false;
      this.p_bg = true;
      this.p_bg_active = false;
      this.monthly_bottom_btn_do = false;
    },
    monthly_bottom_btnOpen_do: function(day) {
      // 実績取得
      let self = this;
      this.do_day = day;
      this.display_totalSale_0_flag = false;
      let year_month = day.split("-");
      let get_only_year = String(year_month[0]);
      let get_only_month = String(year_month[1]).length === 1 ? "0" + String(year_month[1]) : String(year_month[1]);
      let get_only_day = String(year_month[2]).length === 1 ? "0" + String(year_month[2]) : String(year_month[2]);
      this.axios.get(`records/daily/${get_only_year}/${get_only_month}/${get_only_day}`, {
        headers: {
          Authorization: this.$localStorage.get('accessToken')
        }
      })
      .then((response) => {
        if (response.data !== "") {
          self.do_totalSales = response.data.totalSales;
          self.do_techSales = response.data.techSales;
          self.do_itemSales = response.data.itemSales;
          self.do_otherSales = response.data.otherSales;
          self.do_numberOfCustomers = response.data.numberOfCustomers;
          self.do_time = response.data.time;
        } else {
          self.do_totalSales = "";
          self.do_techSales = "";
          self.do_itemSales = "";
          self.do_otherSales = "";
          self.do_numberOfCustomers = "";
          self.do_time = "";
        }
      })
      .catch((e) => {
        console.log(e);
      });

      this.monthly_bottom_btn = true;
      this.monthly_bottom_btn_input = false;
      this.monthly_bottom_btn_input_easy = false;
      this.monthly_bottom_btn_do = true;
      this.monthly_bottom_btn_do_easy = false;
      this.p_bg = true;
      this.p_bg_active = true;
    },
    monthly_bottom_btnOpen_do_easy: function() {
      this.monthly_bottom_btn = true;
      this.monthly_bottom_btn_input = false;
      this.monthly_bottom_btn_input_easy = false;
      this.monthly_bottom_btn_do = false;
      this.monthly_bottom_btn_do_easy = true;
      this.p_bg = true;
      this.p_bg_active = true;
    },
    monthly_bottom_btnClose: function() {
      this.monthly_bottom_btn = false;
      this.monthly_bottom_btn_input = false;
      this.monthly_bottom_btn_input_easy = false;
      this.monthly_bottom_btn_do = false;
      this.monthly_bottom_btn_do_easy = true;
      this.p_bg = false;
      this.p_bg_active = false;
      this.i_bg = false;
      this.i_btn = false;
      this.i_btn_plan = false,
      this.i_btn_do = false,
      this.graf_i_btn = false;
      this.graf_i_year_btn = false;

      this.month_Data = false;
      this.month_Rank = false;
      this.yearly_Data = false;
      this.yearly_Rank = false;
      this.cheack_data = false;

      this.glaf_tab_active = false;
    },

    i_btnOpen: function() {
      this.i_btn = !this.i_btn;
      this.i_btn_plan = !this.i_btn_plan;
      this.i_bg = !this.i_bg;

      let self = this;
      if (this.i_btn_plan) {
        /** information画面に必要なデータ */
        /** 目的系 */
        this.axios.get(`goals/yearly/${self.input_day.input_year}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          if (response.data !== "") {
            self.information_goal_datas = response.data;
          } else {
            self.information_goal_datas = {
              "month" : "",
              "totalSales" : "",
              "techSales" : "",
              "itemSales" : "",
              "otherSales" : "",
              "numberOfCustomers" : "",
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });

      } else {
        self.information_record_datas = [];
      }
    },

    i_btnDoOpen: function() {
      this.i_btn = !this.i_btn;
      this.i_btn_do = !this.i_btn_do;
      this.i_bg = !this.i_bg;
      let self = this;

      if (this.i_btn_do) {
        let mm_month = String(this.input_day.input_month).length === 1 ? "0" + String(this.input_day.input_month) : String(this.input_day.input_month);
        let year_month = String(this.input_day.input_year) + "-" + mm_month;
        /** information画面に必要なデータ */
        /** 目的系 */
        this.axios.get(`records/monthly/${String(this.input_day.input_year)}/${mm_month}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          if (response.data !== "") {
            self.information_record_datas = response.data;
            for (let i = 0; i < self.information_record_datas.length; i++) {
              let array_year_month = year_month.split("-");
              let object_day = new Date(array_year_month[0],array_year_month[1] - 1, i + 1);
              let only_day = object_day.getDate();
              let date = year_month + "-" + ("00" + String(i + 1)).slice(-2)
              self.information_record_datas[i].date = date;
              self.information_record_datas[i].only_day = only_day;
            }
            self.information_record_datas = self.information_record_datas.slice(0,self.days_of_month);
          } else {
            self.information_record_datas = {
              "month" : "",
              "totalSales" : "",
              "techSales" : "",
              "itemSales" : "",
              "otherSales" : "",
              "numberOfCustomers" : "",
            }
          }
          this.get_yearly_graph(this.input_day.input_year)
          this.get_monthly_graph(this.input_day.input_year, this.input_day.input_month)
        })
        .catch((e) => {
          console.log(e);
        });
        

      } else {
        self.information_goal_datas = [];
      }
    },

    graf_i_btnOpen: function() {
      this.graf_i_btn = true;
      this.cheack_data = false;
    },
    graf_i_btnClose: function() {
      this.graf_i_btn = false;
      this.graf_i_year_btn = false;
      this.cheack_data = true;
    },

    graf_i_year_btnOpen: function() {
      this.graf_i_year_btn = true;
      this.cheack_rank = false;
    },

    rank_alertOpen: function() {
      this.rank_alert = true;
    },
    rank_alertClose: function() {
      this.rank_alert = false;
    },
    
    cheack_data_month_RankOpen: function() {
      this.cheack_data = true;
      this.p_bg = true;
      this.month_Rank = true;
      this.month_Data = false;

      this.yearly_Data = false;
      this.yearly_Rank = false;
    },

    cheack_data_month_DataOpen: function(year, month) {
      this.input_day.input_year = year;
      this.input_day.input_month = month;
      this.cheack_data = true;
      this.p_bg = true;
      this.month_Data = true;
      this.month_Rank = false;

      this.yearly_Data = false;
      this.yearly_Rank = false;
    },
    cheack_data_yearly_DataOpen: function() {
      this.cheack_data = true;
      this.p_bg = true;
      this.yearly_Data = true;
      this.yearly_Rank = false;

      this.month_Data = false;
      this.month_Rank = false;
    },

    cheack_data_yearly_RankOpen: function() {
      this.cheack_data = true;
      this.p_bg = true;
      this.yearly_Rank = true;
      this.yearly_Data = false;

      this.month_Data = false;
      this.month_Rank = false;
    },

    glaf_tabOpen: function() {
      this.glaf_tab_active = !this.glaf_tab_active;
    },
    
    get_yearly_graph: function(year) {
      this.$refs.yearly_graph.get_yearly_total_data(year);
    },
    
    get_monthly_graph: function(year, month) {
      this.$refs.monthly_graph.get_monthly_total_data(year, month);
    },

    sendMailmonthly: function() {
      this.monthly_bottom_btnClose();
      this.p_bg = true;
      this.sended = true;

      let csvArray = []
      csvArray.push([this.input_day.input_year+'年'+this.input_day.input_month+'月', '総売上', '技術売上', '商品売上', 'その他売上', '総客数', '総客単価', '技術客単価', '商品客単価', 'その他単価', '仕事時間', '総時間単価', '技術時間単価', '商品時間単価', 'その他時間単価'])
      for (let data in this.information_monthly_data) {
        let tmpArray = []
        tmpArray.push(Number(data) + 1 + '日')
        tmpArray.push(this.information_monthly_data[data].totalSales)
        tmpArray.push(this.information_monthly_data[data].techSales)
        tmpArray.push(this.information_monthly_data[data].itemSales)
        tmpArray.push(this.information_monthly_data[data].otherSales)
        tmpArray.push(this.information_monthly_data[data].numberOfCustomers)
        tmpArray.push(this.unit_round_method(this.information_monthly_data[data].totalSales, this.information_monthly_data[data].numberOfCustomers))
        tmpArray.push(this.unit_round_method(this.information_monthly_data[data].techSales, this.information_monthly_data[data].numberOfCustomers))
        tmpArray.push(this.unit_round_method(this.information_monthly_data[data].itemSales, this.information_monthly_data[data].numberOfCustomers))
        tmpArray.push(this.unit_round_method(this.information_monthly_data[data].otherSales, this.information_monthly_data[data].numberOfCustomers))
        tmpArray.push(this.information_monthly_data[data].time)
        tmpArray.push(this.unit_round_method(this.information_monthly_data[data].totalSales, this.information_monthly_data[data].time))
        tmpArray.push(this.unit_round_method(this.information_monthly_data[data].techSales, this.information_monthly_data[data].time))
        tmpArray.push(this.unit_round_method(this.information_monthly_data[data].itemSales, this.information_monthly_data[data].time))
        tmpArray.push(this.unit_round_method(this.information_monthly_data[data].otherSales, this.information_monthly_data[data].time))
        csvArray.push(tmpArray)
      }
      
      var formatCSV = '';
      for (var i = 0; i < csvArray.length; i++) {
          var value = csvArray[i];

          for (var j = 0; j < value.length; j++) { var innerValue = value[j]===null?'':value[j].toString(); var result = innerValue.replace(/"/g, '""'); if (result.search(/("|,|\n)/g) >= 0)
          result = '"' + result + '"';
          if (j > 0)
          formatCSV += ',';
          formatCSV += result;
        }
        formatCSV += '\n';
      }
      
      const params = new URLSearchParams();
      params.append('email', this.email);
      params.append('csv', formatCSV);
      console.log(this.email);
      this.axios.post('csv_email', params)
      .then((response) => {
        console.log(response);
      })
      .catch((e) => {
        console.log(e);
      });
    },

    sendMailYearly: function() {
      this.monthly_bottom_btnClose();
      this.p_bg = true;
      this.sended = true;

      let csvArray = []
      csvArray.push([this.input_day.input_year+'年', '総売上', '技術売上', '商品売上', 'その他売上', '総客数', '総客単価', '技術客単価', '商品客単価', 'その他単価', '仕事時間', '営業日数',  '１日売上', '総時間単価', '技術時間単価', '商品時間単価', 'その他時間単価'])
      for (let data in this.information_yearly_data) {
        let tmpArray = []
        tmpArray.push(Number(data) + 1 + '月')
        tmpArray.push(this.information_yearly_data[data].totalSales)
        tmpArray.push(this.information_yearly_data[data].techSales)
        tmpArray.push(this.information_yearly_data[data].itemSales)
        tmpArray.push(this.information_yearly_data[data].otherSales)
        tmpArray.push(this.information_yearly_data[data].numberOfCustomers)
        tmpArray.push(this.unit_round_method(this.information_yearly_data[data].totalSales, this.information_yearly_data[data].numberOfCustomers))
        tmpArray.push(this.unit_round_method(this.information_yearly_data[data].techSales, this.information_yearly_data[data].numberOfCustomers))
        tmpArray.push(this.unit_round_method(this.information_yearly_data[data].itemSales, this.information_yearly_data[data].numberOfCustomers))
        tmpArray.push(this.unit_round_method(this.information_yearly_data[data].otherSales, this.information_yearly_data[data].numberOfCustomers))
        tmpArray.push(this.information_yearly_data[data].time)
        tmpArray.push(this.information_yearly_data[data].workingDays)
        tmpArray.push(this.unit_round_method(this.information_yearly_data[data].totalSales, this.information_yearly_data[data].workingDays))
        tmpArray.push(this.unit_round_method(this.information_yearly_data[data].totalSales, this.information_yearly_data[data].time))
        tmpArray.push(this.unit_round_method(this.information_yearly_data[data].techSales, this.information_yearly_data[data].time))
        tmpArray.push(this.unit_round_method(this.information_yearly_data[data].itemSales, this.information_yearly_data[data].time))
        tmpArray.push(this.unit_round_method(this.information_yearly_data[data].otherSales, this.information_yearly_data[data].time))
        csvArray.push(tmpArray)
      }

      var formatCSV = '';
      for (var i = 0; i < csvArray.length; i++) {
        var value = csvArray[i];

        for (var j = 0; j < value.length; j++) { 
          var innerValue = value[j] === null ? '' : value[j].toString(); 
          var result = innerValue.replace(/"/g, '""'); 
          if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
          if (j > 0) formatCSV += ',';
        formatCSV += result;
        }
        formatCSV += '\n';
      }

      const params = new URLSearchParams();
      params.append('email', this.email);
      params.append('csv', formatCSV);
      console.log(this.email);
      this.axios.post('csv_email', params)
      .then((response) => {
        console.log(response);
      })
      .catch((e) => {
        console.log(e);
      });
    },
    all_Close: function() {
      this.sended = false;
      this.p_bg = false;
    },
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

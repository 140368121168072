<template>
    <div id="btn">

        <header>
            <div class="header_inner">
                <div class="h1">
                    <h1>
                    <figure><img src="../assets/img/logo.png" alt="WAKUWAKUSUCHI" width="202px"></figure>
                    </h1>
                </div>

                <div class="header_right">
                    <button  @click="userOpen" :class="{'is-user_active': active}">
                        <figure>
                            <img src="../assets/img/user_icon.png" width="30" height="30">
                        </figure>
                        <div>
                            <p>{{ name }}</p>
                            <p>{{ area }}</p>
                        </div>
                    </button>
                </div>
            </div> <!-- .header_inner -->
        </header>

        <transition name="fade">
        <section id="header_user" v-show="user_navi">
                <div class="header_user_inner">
                    <h2><span></span>USER<span></span></h2>
                    <div class="btn_menu" type="button" onclick="">
                        <button class="menu_trigger"  @click="user_detailsOpen" :class="{'is-user_details_active': active}">
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    <button class="btn_close" type="button" onclick=""  @click="userOpen" :class="{'is-user_details_active': active}">×</button>

                    <dl>
                        <dt>メールアドレス（変更不可）</dt>
                        <dd><input v-model="email" type="email" placeholder="メールアドレス" disabled></dd>
                        <p v-if="empty_email" style="text-align: center; color: red;">メールアドレスは必須です</p>
                        <dt>パスワード（変更の場合のみ二回入力）</dt>
                        <dd><input v-model="password" type="password" ></dd>
                        <dd><input v-model="password_2" type="password" ></dd>
                        <p v-if="wrong_password" style="text-align: center; color: red;">※パスワードが違います</p>
                        <dt>名前</dt>
                        <dd><input v-model="name" type="text" placeholder="名前" value="ワクワク太郎"></dd>
                        <p v-if="empty_name" style="text-align: center; color: red;">名前は必須です</p>
                        <dd class="flex_side">
                            <input v-model="age" type="text" placeholder="年齢">
                            <select v-model="gender" name="sex">
                                <option value="1">男性</option>
                                <option value="2">女性</option>
                            </select>
                        </dd>
                        <p v-if="empty_age" style="text-align: center; color: red;">年齢は必須です</p>
                        <dt>活動地域</dt>
                        <dd>
                          <select v-model="area" placeholder="活動地域">
                            <option v-for="prefecture in prefectures" :value="prefecture" :key="prefecture">{{ prefecture }}</option>
                          </select>
                        </dd>
                    </dl>
                        <button v-bind:disabled="button_available" class="submit" @click="editUser">更新</button>
                </div>
        </section>
        </transition>

        <transition name="fade">
        <section id="header_logout" v-show="user_details_navi">
            <div class="header_logout_inner">
                <h2><span></span>DETAIL<span></span></h2>
                <button class="btn_close" type="button" @click="user_detailsOpen" :class="{'is-user_details_active': active}">
                    ←
                </button>

                <button class="logout_btn" @click="logout"><figure><img src="../assets/img/logout.png"></figure>ログアウト</button>
                <div>
                    <button class="user_info_btn active" @click="deleteUser" v-show="user_deleteOn"><figure><img src="../assets/img/delete.png"></figure>ユーザー情報削除</button>
                    <button class="user_info_btn" v-show="user_deleteOff"><figure><img src="../assets/img/delete.png"></figure>ユーザー情報削除</button>
                    <label><input type="checkbox" @click="user_delete">ユーザー情報を削除する。</label>
                    <p>※ユーザー情報を削除すると全ての情報が削除され、元に戻りません。</p>
                </div>
            </div>
        </section>
        </transition>

        <transition name="fade">
        <div id="bg_black" v-show="bg"></div>
        </transition>
        </div> <!-- #btn -->
    
</template>

<style>
    .fade-enter-active, .fade-leave-active
     {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>

<script>
   export default {
    data: () => ({
      haikei: false,
      new_edit: false,
      email: "",
      password: "",
      password_2: "",
      name: "",
      age: "",
      gender: 1,
      area: "",

      active: false,
      navi: false,
      user_active: false,
      user_navi: false,

      user_details_active: false,
      user_details_navi: false,

      user_deleteOn: false,
      user_deleteOff: true,

      button_available: false,
      bg: false,
      prefectures: [
        '北海道',
        '青森県',
        '岩手県',
        '宮城県',
        '秋田県',
        '山形県',
        '福島県',
        '茨城県',
        '栃木県',
        '群馬県',
        '埼玉県',
        '千葉県',
        '東京都',
        '神奈川県',
        '新潟県',
        '富山県',
        '石川県',
        '福井県',
        '山梨県',
        '長野県',
        '岐阜県',
        '静岡県',
        '愛知県',
        '三重県',
        '滋賀県',
        '京都府',
        '大阪府',
        '兵庫県',
        '奈良県',
        '和歌山県',
        '鳥取県',
        '島根県',
        '岡山県',
        '広島県',
        '山口県',
        '徳島県',
        '香川県',
        '愛媛県',
        '高知県',
        '福岡県',
        '佐賀県',
        '長崎県',
        '熊本県',
        '大分県',
        '宮崎県',
        '鹿児島県',
        '沖縄県',
        '海外',
      ],
    }),
    methods: {
      logout: function() {
        this.$localStorage.remove('accessToken')
        this.$router.push({ name: 'Login' })
      },
      editUser: function() {
        const params = new URLSearchParams();
        params.append('email', this.email);
        params.append('name', this.name);
        params.append('age', this.age);
        params.append('gender', this.gender);
        params.append('area', this.area);
        if (this.password == this.password_2) {
          params.append('password', this.password);
        }
        this.axios.post('user_update', params,{
          headers:{
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then(() => {
          this.$router.go({path: this.$router.currentRoute.path, force: true})
        })
        .catch((e) => {
          console.log(e)
        })
      },
      deleteUser: function() {
        //user_deleteはまだ実装していない
        this.axios.get('user_delete',{
          headers:{
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then(() => {
          this.$localStorage.remove('accessToken')
          this.$router.push({ name: 'deleted' })
        })
        .catch((e) => {
          console.log(e)
        })
      },
      naviOpen: function() {
        this.active = !this.active;
        this.navi = !this.navi;
        this.bg = !this.bg;
      },
      userOpen: function() {
        this.user_active = !this.user_active;
        this.user_navi = !this.user_navi;
        this.bg = !this.bg;
      },
      user_detailsOpen: function() {
        this.user_details_active = !this.user_details_active;
        this.user_details_navi = !this.user_details_navi;

        this.user_active = !this.user_active;
        this.user_navi = !this.user_navi;
      },
      user_delete: function() {
        this.user_deleteOff = !this.user_deleteOff;
        this.user_deleteOn = !this.user_deleteOn;
      }
    },
    computed: {
      wrong_password: function() {
        if (this.password_2 && this.password != this.password_2) {
          this.button_available = true // eslint-disable-line
          return true
        } else {
          this.button_available = false // eslint-disable-line
          return false
        }
      },
      empty_email: function() {
        if (!this.email) {
          this.button_available = true // eslint-disable-line
          return true
        } else {
          this.button_available = false // eslint-disable-line
          return false
        }
      },
      empty_name: function() {
        if (!this.name) {
          this.button_available = true // eslint-disable-line
          return true
        } else {
          this.button_available = false // eslint-disable-line
          return false
        }
      },
      empty_age: function() {
        if (!this.age) {
          this.button_available = true // eslint-disable-line
          return true
        } else {
          this.button_available = false // eslint-disable-line
          return false
        }
      }
    },
    created: function() {
      this.axios.get('reGenToken', {
        headers: {
          Authorization: this.$localStorage.get('accessToken')
        }
      })
      .then((response) => {
        this.$localStorage.set('accessToken', response.data.accessToken)
      })
      .catch((e) => {
        console.log(e)
        this.$router.push({ name: 'Login' })
      })

      this.axios.get('users',{
        headers:{
          Authorization: this.$localStorage.get('accessToken')
        }
      })
      .then(response => {
        const user = response.data[0]
        this.email = user.email
        this.name = user.name
        this.age = user.age
        this.gender = user.gender
        this.area = user.area
        this.$emit('set-email', user.email);
			})
      .catch(e => {
        console.log(e)
        this.$router.push({ name: 'Login' })
			})
    },
   }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

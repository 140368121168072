<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  name: 'chart',
  data () {
    return {
      data: {
        labels: [],
        datasets: [
          {
            label: '目標',
            data: [],
            borderColor: '#CFD8DC',
            pointRadius: 0,
            fill: false,
            type: 'line',
            lineTension: 0.3,
          },
          {
            label: '実績',
            data: [],
            borderColor: "#D69090",
            backgroundColor: "#D69090"
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            ticks: {
              maxTicksLimit: 5,  // 最大表示数
              stepSize: 10000,
            },
            scaleLabel: {
              display: true,
              labelString: ''
            }
          }],
          yAxes: [{
            ticks: {
              min: 0,      // 最小値
              maxTicksLimit: 8,  // 最大表示数
              stepSize: 1000000,
            }
          }]
        }
      }
    }
  },
  methods: {
    get_yearly_total_data: function (year) {
      this.axios.get(`records/yearly-total/${year}`, {
        headers: {
          Authorization: this.$localStorage.get('accessToken')
        }
      })
      .then((response) => {
        this.data.labels = []
        this.data.datasets[1].data = []
        let preTotalSales = 0

        response.data.forEach((data, index) => {
          this.data.labels.push(String(index + 1))
          this.data.datasets[1].data.push(Number(preTotalSales) + Number(data.totalSales))
          preTotalSales = Number(preTotalSales) + Number(data.totalSales)
        })
        this.axios.get(`goals/yearly/${year}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          this.data.datasets[0].data = []
          let preTotalSales = 0

          response.data.forEach((data) => {
            this.data.datasets[0].data.push(Number(preTotalSales) + Number(data.totalSales))
            preTotalSales = Number(preTotalSales) + Number(data.totalSales)
          })
          if (this.data.datasets[0].data) {
            this.renderChart(this.data, this.options)
          }
        })
        .catch((e) => {
          console.log(e)
        })

      })
      .catch((e) => {
        console.log(e)
      })
    }
  }
}
</script>
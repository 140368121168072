<template>
  <div id="main">
      
  <Header @set-email="setUserEmail" />
    <main id="top">
      <!--<h2>-list-</h2>-->
      <div class="select_btn">
        <button class="first_btn"><router-link :to="{ path : '/'}"><figure><img src="../assets/img/calendar_icon.png" width="20" height="20"></figure><p>calendar</p></router-link></button>
        <button class="active"><figure><img src="../assets/img/list_icon.png" width="20" height="20"></figure><p>list</p></button>
        <router-link :to="{ name : 'daily', params: {day_from_calendar : to_day}}"><button><span>{{ only_day }}</span><p>today</p></button></router-link>
      </div>

      <section class="weekly">
        <div class="weekly_header">
          <button @click="monthly_bottom_btnOpen(input_day.input_year ,input_day.input_month)"><figure><img src="../assets/img/flag_g.png" width="16" height="16"></figure>
            <span v-if="goals_totalSales_per_month">{{ Number(goals_totalSales_per_month).toLocaleString() }}</span> 
            <span v-else>-</span> 
          </button>
          <div><figure><img src="../assets/img/write.png" width="20" height="20"></figure>
            <span v-if="recoreds_totalSales_per_month">{{ Number(recoreds_totalSales_per_month).toLocaleString() }}</span> 
            <span v-else>-</span> 
          </div>
          <button class="graf_btn" @click="cheack_data_month_DataOpen(input_day.input_year ,input_day.input_month)"><figure><img src="../assets/img/graf.png" alt="グラフ" width="40px" height="40px"></figure></button>
        </div>

        <div class="month_tab">
          <span class="select_arrow">
            <select v-model="input_day.input_year">
              <option v-for="year in years" :key="year">{{ year }}</option>
            </select>
          </span>
          <span>/</span>
          <span class="select_arrow">
            <select v-model="input_day.input_month">
              <option v-for="month in months" :key="month">{{ month }}</option>
            </select>
          </span>
        </div>

        <div class="scroll_icon">▲</div>

        <div class="weekly_days">
            
          <div class="weekly_days-cell" v-for="records_data in records_datas" :key="records_data.only_day" v-bind:class="{'sunday': records_data.day_of_week === '日','saturday': records_data.day_of_week === '土'}">
            <router-link :to="{ name : 'daily', params: {day_from_calendar : records_data.date}}">
            <div class="weekly_days-cell_inner">
              <div>
                
                  <span>{{ records_data.only_day }}</span>
                  <span>{{ records_data.day_of_week }}曜日</span>
                
                <!-- <span>{{ records_data.only_day }}</span>
                <span>{{ records_data.day_of_week }}曜日</span> -->
              </div>
              <div>
                <p>{{ goals_totalSales_per_day && records_data.totalSales !== "-"  ? Math.round(records_data.totalSales / goals_totalSales_per_day * 100) : 0 }}%</p>
              </div>
              <div class="">
                <figure><img src="../assets/img/flag_g.png" width="16" height="16"></figure>
                <p>
                  <span v-if="goals_totalSales_per_day">{{ Number(goals_totalSales_per_day).toLocaleString() }}</span>
                  <span v-else>-</span> 
                </p>
              </div>
            </div>
            <div class="wright">
              <figure><img src="../assets/img/write.png" width="20" height="20"></figure>
              <span v-if="records_data.totalSales">{{ Number(records_data.totalSales).toLocaleString() }}</span>
              <span v-else>-</span>
            </div>
            </router-link>
          </div>

        </div>
        <div class="scroll_icon">▼</div>
      </section><!-- .weekly -->


    </main>

    <Btnclick ref="btn_click"
      :email="email"
      v-on:input_new_data="new_goals_data = $event"
    />

    
    <!--<div>
       v-ifで<script>send_alert 
      
      <transition name="fade">
        <div class="flash" v-if="send_alert">
          更新しました
        </div>
      </transition>

       clickしたらmethods:{}内のshowFlashが発火する 
      <button @click="showFlash">
        send_alert flash message
      </button>
    </div>-->
  <Footer/>
  </div>
</template>


<script>
// @ is an alias to /src
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Btnclick from '@/components/daily_btn_click.vue'

  export default {
    name: 'Home',
    components: {
      Header,
      Btnclick,
      Footer
    },
    data: function() {
      return {
        /** selectの中身 */
        months: [1,2,3,4,5,6,7,8,9,10,11,12],
        years: [2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030],

        /** 日付系 */
        to_day:new Date(),
        // 選択年月をyyyy-mm形式で保持（子コンポーネントから返ってきた時に比較するため）
        year_month: "",
        only_day:"",                           //日にちのみ表示用
        days_of_month:"",                      //日数の保持
        /** 目標系 */
        input_day:{
          input_year:"",
          input_month:""
        },
        goals_totalSales_per_month:"",
        goals_totalSales_per_day:"",

        /** 実績系 */
        records_datas: "",
        recoreds_totalSales_per_month:"",

        /** information画面用 */
        // month_datas:[],
        /** 子コンポーネントから */
        new_goals_data: "",

        email: "",
      }
    },
    mounted() {
      //何かしらからinput_day.input_yearかinput_day.input_monthが入っていないかぎり、今月を入れる
      if (this.input_day.input_year === "" || this.input_day.input_month === "") {
        //日付をdateオブジェクト化:object_day
        let today_object = new Date();
        //選択された月:this.input_day.input_month
        this.input_day.input_month = today_object.getMonth() + 1;
        //選択された月:this.input_day.input_year
        this.input_day.input_year = today_object.getFullYear();
      }
    },
    watch: {
      input_day :{
        handler : function(){
          let self = this;
          const weeks = ['日', '月', '火', '水', '木', '金', '土'];

          //今日の取得
          let today_object = new Date();
          this.to_day = today_object.getFullYear() + "-" + ("00" + (today_object.getMonth()+1)).slice(-2) + "-" + ("00" + today_object.getDate()).slice(-2);
          //日にちのみ取得
          this.only_day = today_object.getDate();

          //日数の取得:days_of_month
          let next_month = new Date(this.input_day.input_year, this.input_day.input_month , 0);
          this.days_of_month = next_month.getDate();

          /** 目標系 */
          let mm_month = String(this.input_day.input_month).length === 1 ? "0" + String(this.input_day.input_month) : String(this.input_day.input_month);
          this.axios.get(`goals/monthly/${String(this.input_day.input_year)}/${mm_month}`, {
            headers: {
              Authorization: this.$localStorage.get('accessToken')
            }
          })
          .then((response) => {
            if (response.data !== "") {
              self.goals_totalSales_per_month = response.data.totalSales;
              self.goals_totalSales_per_day = !response.data.totalSales ? "" : Math.round(response.data.totalSales / self.days_of_month);
            } else {
              self.goals_totalSales_per_month = "";
              self.goals_totalSales_per_day = "";
            }
          })
          .catch((e) => {
            console.log(e);
          });

          /** 実績取得 */
          this.axios.get(`records/monthly/${String(this.input_day.input_year)}/${mm_month}`, {
            headers: {
              Authorization: this.$localStorage.get('accessToken')
            }
          })
          .then((response) => {
            if (response.data !== "") {
              self.records_datas = response.data;
              for (let i = 0; i < self.records_datas.length; i++) {
                let object_day = new Date(this.input_day.input_year,this.input_day.input_month - 1, i + 1);
                let only_day = object_day.getDate();
                let day_of_week = weeks[object_day.getDay()];
                self.records_datas[i].only_day = only_day;
                self.records_datas[i].day_of_week = day_of_week;
              }
              self.records_datas = self.records_datas.slice(0,self.days_of_month);
              console.log(self.records_datas);
            } else {
              self.records_datas = [{
                "totalSales": "",
                "techSales": "",
                "itemSales": "",
                "otherSales": "",
                "numberOfCustomers": "",
                "time": "",
                "date": ""
              }];
            }
          })
          .catch((e) => {
            console.log(e);
          });
        },
        deep:true
      },
      new_goals_data:{
        handler : function(){
          if (this.new_goals_data !== "") {
            console.log(this.new_goals_data);
            if (this.new_goals_data.year === this.input_day.input_year && this.new_goals_data.month === this.input_day.input_month) {
              this.goals_totalSales_per_month = this.new_goals_data.totalSales;
              this.goals_totalSales_per_day = Math.round(this.new_goals_data.totalSales / this.days_of_month);
            } else {
              this.input_day.input_year = this.new_goals_data.year;
              this.input_day.input_month = this.new_goals_data.month;
            }
          }
        },
        deep:true
      },
       records_datas:{
        handler : function(){
          if (this.records_datas === "") {
            this.recoreds_totalSales_per_month = "";
          } else {
            let record_data_array = this.records_datas;
            if (record_data_array.length > 0) {
              this.recoreds_totalSales_per_month = 0;
              for (let i = 0; i < record_data_array.length; i++) {
                this.recoreds_totalSales_per_month += Number(record_data_array[i].totalSales);
              }
            } else {
              this.recoreds_totalSales_per_month = "";
            }
          }
        },
        deep:true
      },
    },
    methods: {
      //showFlash(){
      //  this.send_alert = true;
      // setTimeoutで3000ms後にshowをfalseにする
      //  setTimeout(() => {
      //   this.send_alert = false}
      //    ,3000)
      //},
      monthly_bottom_btnOpen (year ,month) {
        this.$refs.btn_click.monthly_bottom_btnOpen(year ,month)
      },
      cheack_data_month_DataOpen (year,month) {
        this.$refs.btn_click.cheack_data_month_DataOpen(year,month)
      },
      monthly_bottom_btnOpen_do () {
        this.$refs.btn_click.monthly_bottom_btnOpen_do()
      },
      setUserEmail(email) {
        this.email = email
      },
    }
}
</script>
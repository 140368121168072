<template>
  <div>
      <div id="footer_banner">
      <hooper :auto-play="true" :play-speed="5000" :transition="1000">
        <slide><a href="https://ebs8.jp/" target="blank"><figure><img src ="../assets/img/banner_1.png" alt=""></figure></a></slide>
        <slide><a href="https://order.ebs8.jp/" target="blank"><figure><img src ="../assets/img/banner_2.png" alt=""></figure></a></slide>
        <slide><a href="https://order.ebs8.jp/" target="blank"><figure><img src ="../assets/img/banner_3.png" alt=""></figure></a></slide>
        <slide><a href="https://ebs8.jp/seminar1/" target="blank"><figure><img src ="../assets/img/banner_4.png" alt=""></figure></a></slide>
      </hooper>
      </div>
    <footer>
      <figure><img src="../assets/img/logo.png" width="256" height="73"></figure>
    </footer>
  </div>

</template>

    <style>
      .hooper {
        height: auto !important;
      }
    </style>

<script>
   export default {
      components: {
          Hooper: window.Hooper.Hooper,
          Slide: window.Hooper.Slide
      },
   }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

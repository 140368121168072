<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  name: 'chart',
  data () {
    return {
      items: {
        labels: [],
        datasets: [
          {
            label: '目標',
            data: [],
            borderColor: '#CFD8DC',
            pointRadius: 0,
            fill: false,
            type: 'line',
            lineTension: 0.3,
          },
          {
            label: '実績',
            data: [],
            borderColor: "#D69090",
            backgroundColor: "#D69090"
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: ''
            }
          }],
          yAxes: [{
            ticks: {
              min: 0,      // 最小値
              maxTicksLimit: 8,  // 最大表示数
              stepSize: 10000,
            }
          }]
        }
      }
    }
  },
  methods: {
    get_monthly_total_data: function (year,month) {
      this.axios.get(`records/monthly/${year}/${month}`, {
        headers: {
          Authorization: this.$localStorage.get('accessToken')
        }
      })
      .then((response) => {
        this.items.labels = []
        this.items.datasets[1].data = []
        let preTotalSales = 0

        response.data.forEach((data, index) => {
          this.items.labels.push(String(index + 1))
          this.items.datasets[1].data.push(Number(preTotalSales) + Number(data.totalSales))
          preTotalSales = Number(preTotalSales) + Number(data.totalSales)
        })
        this.axios.get(`goals/monthly/${year}/${month}`, {
          headers: {
            Authorization: this.$localStorage.get('accessToken')
          }
        })
        .then((response) => {
          this.items.datasets[0].data = []
          this.items.labels.forEach(() => {
            this.items.datasets[0].data.push(Number(response.data.totalSales ? response.data.totalSales : 0))
          })
          if (this.items.datasets[0].data) {
            this.renderChart(this.items, this.options)
          }
        })
        .catch((e) => {
          console.log(e)
        })

      })
      .catch((e) => {
        console.log(e)
      })
    }
  }
}
</script>
<template>
  <div id="main">
        <header>
            <div class="header_inner">
                <h1>
                    <figure><img src="img/logo.png" alt="WAKUWAKU電子手帳" width="256" height="73"></figure>
                </h1>
            </div>
        </header>

        <main id="">
  <section class="notice">
    <p>
      ユーザを削除しました。<br>
    </p>
    <button class="btn_close" @click="go_top">TOPに戻る</button>
</section>

        </main>
</div>

</template>


<script>
// @ is an alias to /src

export default {
  name: 'error',
  methods: {
    go_top: function() {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

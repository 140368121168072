import Vue from 'vue'
import VueRouter from 'vue-router'
import monthly from '../views/monthly.vue'
import weekly from '../views/weekly.vue'
import daily from '../views/daily.vue'

import login from '../views/login.vue'
import Direct from '../views/login_direct.vue'

import Registry from '../views/login_registry.vue'

import index from '../views/Home.vue'

import deleted from '../views/deleted.vue'
import error from '../views/error.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: index
  },  
  {
    path: '/deleted',
    name: 'deleted',
    component: deleted
  },
  {
    path: '/error',
    name: 'error',
    component: error
  },
  {
    path: '/login',
    name: 'Login',
    component: login
  },
  {
    path: '/',
    name: 'calendar',
    component: monthly
  },
  {
    path: '/list',
    name: 'list',
    component: weekly
  },
  {
    path: '/daily/:day_from_calendar',
    name: 'daily',
    component: daily
  },

  //新規登録ページ
  {
    path: '/registry',
    name: 'Registry',
    component: Registry
  },

  //利用規約
  {
    path: '/login_direct',
    name: 'Direct',
    component: Direct
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
